import { ADD_GLOBAL_ERROR, CLEAR_GLOBAL_ERRORS } from '../actions/actionTypes';
import createReducer from './createReducer';

export const initialState = {
    errors: []
};

const ACTION_HANDLERS = {
    [ADD_GLOBAL_ERROR]: (state, { error }) => ({ errors: [...state.errors, error] }),
    [CLEAR_GLOBAL_ERRORS]: (state, { timestamp }) => ({
        errors: state.errors.filter(error => error.timestamp !== timestamp)
    })
};

export default createReducer(initialState, ACTION_HANDLERS);
