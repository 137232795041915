import React from 'react';
import cn from 'classnames';
import { object, number, string } from 'prop-types';
import './Loader.scss';

const Loader = ({ style, className, width, speed, strokeWidth, ...props }) => {
    // pad the rect so the circle is not cut off
    const size = width * 2.5;
    // radius = 1/2 size of viewbox
    const radius = size / 2;
    const s = `${speed}ms`;
    // NOTE - style can be overwritten with the exception of width/height - use width prop
    return (
        <div
            {...props}
            className={cn('svg-loader', className)}
            style={{
                ...style,
                width,
                height: width
            }}
        >
            <svg width={width} height={width} viewBox={`0 0 ${size} ${size}`} preserveAspectRatio="xMidYMid">
                <rect x="0" y="0" width={size} height={size} fill="none" />
                <circle
                    cx={`${radius}`}
                    cy={`${radius}`}
                    r={width}
                    strokeDasharray={`${size * 2} ${radius}`}
                    stroke="currentColor"
                    fill="none"
                    strokeWidth={strokeWidth}
                >
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        values={`0 ${radius} ${radius};180 ${radius} ${radius};360 ${radius} ${radius};`}
                        keyTimes="0;0.5;1"
                        dur={s}
                        repeatCount="indefinite"
                        begin="0s"
                    />
                </circle>
            </svg>
        </div>
    );
};

Loader.propTypes = {
    width: number,
    strokeWidth: number,
    speed: number,
    style: object,
    className: string
};

Loader.defaultProps = {
    width: 120,
    strokeWidth: 5,
    speed: 1500,
    style: {},
    className: null
};
export default Loader;
