import request from '../api/api';

const url = `${process.env.REACT_APP_API_ENDPOINT}/seasons/current/overrides`;

const deleteAll = data => request({ method: 'DELETE', url, data });

const update = data => request({ method: 'POST', url: data.StoreNumber ? `${url}/store` : `${url}/material`, data });

const deleteIsAReservedKeyword = data =>
    request({ method: 'DELETE', url: data.StoreNumber ? `${url}/store` : `${url}/material`, data });

export default {
    update,
    deleteAll,
    delete: deleteIsAReservedKeyword
};
