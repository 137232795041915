import React from 'react';
import { oneOf, func } from 'prop-types';
import cn from 'classnames';
import './GroupBy.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';

export const groupBy = {
    SEASON: 'season',
    DIVISION: 'division',
    CATEGORY: 'category',
    PARTNER: 'partner',
    STORE: 'store',
    MATERIAL: 'material',
    MATERIAL_STORE: 'material_store'
};

const groupByList = [groupBy.SEASON, groupBy.DIVISION, groupBy.CATEGORY, groupBy.PARTNER, groupBy.STORE, groupBy.MATERIAL];

const GroupBy = ({ active, setGroupBy, getShipplan }) => {
    const handleGroupByClick = group => {
        setGroupBy(group);
        getShipplan(0);
    };
    return (
        <div className="GroupBy">
            <h3>
                <FontAwesomeIcon icon={faLayerGroup} />
                &nbsp; Group by
            </h3>
            <ul className="GroupBy__list">
                {groupByList.map(group => (
                    <li key={group} className={cn('GroupBy__listitem', { 'GroupBy__listitem--active': group === active })}>
                        <button className="GroupBy__button" type="button" onClick={() => handleGroupByClick(group)}>
                            {group}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

GroupBy.propTypes = {
    setGroupBy: func.isRequired,
    getShipplan: func.isRequired,
    active: oneOf(groupByList)
};

export default GroupBy;
