import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import { getSeason } from '../../actions/season';
import {
    applyPartnerProposals,
    uploadPartnerProposalSuccess,
    uploadPartnerProposalFailure
} from '../../actions/partnerProposals';
import withSeason from '../../hoc/withSeason';
import PartnerProposals from './PartnerProposals';

const mapDispatchToProps = {
    getSeason,
    applyPartnerProposals,
    uploadPartnerProposalSuccess,
    uploadPartnerProposalFailure
};

const mapStateToProps = ({ partnerProposals, season: { season } }) => ({
    partnerProposals: get(season, 'partnerProposals', undefined),
    applyPartnerProposalsLoading: get(partnerProposals, 'applyPartnerProposalsLoading', false),
    actions: get(season, 'actions', [])
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withSeason, withRouter)(PartnerProposals);
