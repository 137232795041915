import React, { Component } from 'react';
import { shape, func, string, number, bool } from 'prop-types';
// import Button from '../Button/Button';
import FileInfo from '../FileInfo/FileInfo';
import UploadFile from '../UploadFile/UploadFile';

export const fileTypes = {
    XLSX: {
        extension: 'xlsx',
        mimeType: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    CSV: {
        extension: 'csv',
        mimeType: ''
    }
};

export default class SourceFile extends Component {
    static propTypes = {
        getSeason: func.isRequired,
        endpoint: string.isRequired,
        title: string.isRequired,
        type: shape({
            extension: string,
            mimeType: string
        }).isRequired,
        onError: func,
        file: shape({
            filename: string,
            totalQuantity: number,
            status: string
        }),
        allowReupload: bool,
        resetAfterUpload: bool,
        onSuccess: func
    };

    static defaultProps = {
        allowReupload: false,
        onSuccess: () => {}
    };

    state = {
        reUpload: false
    };

    handleToggle = () => {
        const { reUpload } = this.state;
        this.setState({ reUpload: !reUpload });
    };

    handleSuccess = () => {
        const { getSeason, onSuccess } = this.props;
        this.setState({ reUpload: false });
        getSeason();
        onSuccess();
    };

    render() {
        const { title, file, type, endpoint, onError, allowReupload, resetAfterUpload } = this.props;
        const { reUpload } = this.state;
        if (file && !reUpload) {
            return <FileInfo {...file} title={title} showToggle={allowReupload} onToggle={this.handleToggle} />;
        }
        return (
            <UploadFile
                title={`${title} (.${type.extension})`}
                showToggle={allowReupload}
                onToggle={file ? this.handleToggle : null}
                endpoint={endpoint}
                onSuccess={this.handleSuccess}
                onError={onError}
                mimeType={type.mimeType}
                resetAfterUpload={resetAfterUpload}
            />
        );
    }
}
