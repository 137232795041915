import { get } from 'lodash';
import {
    GET_MATERIAL,
    GET_MATERIAL_FAILURE,
    GET_MATERIAL_SUCCESS,
    MATERIAL_SET_SORTING,
    OVERRIDE_SUCCESS
} from '../actions/actionTypes';
import createReducer from './createReducer';

export const initialState = {
    material: undefined,
    getMaterialPending: false,
    getMaterialSuccess: false,
    getMaterialError: undefined,
    sorting: undefined
};

const ACTION_HANDLERS = {
    [GET_MATERIAL]: () => ({ getMaterialPending: true, getMaterialSuccess: false, getMaterialError: undefined }),
    [GET_MATERIAL_FAILURE]: (state, { error }) => ({
        getMaterialPending: false,
        getMaterialError: error
    }),
    [GET_MATERIAL_SUCCESS]: (state, { material }) => ({
        getMaterialPending: false,
        getMaterialSuccess: true,
        getMaterialError: undefined,
        material
    }),
    [MATERIAL_SET_SORTING]: (state, { sorting }) => ({ sorting }),
    [OVERRIDE_SUCCESS]: (state, { materialInfo }) => {
        const stores = get(state, 'material.stores', []).map(store =>
            materialInfo.stores.find(stateStore => stateStore.StoreNumber === store.StoreNumber)
        ); // eslint-disable-line
        return {
            material: {
                ...materialInfo,
                stores
            }
        };
    }
};

export default createReducer(initialState, ACTION_HANDLERS);
