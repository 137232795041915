import { connect } from 'react-redux';
import { overrideMaterialStore, overrideDelete } from '../../actions/override';
import { isUpdating } from '../../reducers/override';
import OverrideMaterialStore from './OverrideMaterialStore';

const mapActionCreators = {
    overrideMaterialStore,
    overrideDelete
};

const mapStateToProps = (state, { material, disabled }) => {
    const updating = isUpdating(state, material);

    return {
        disabled: disabled || updating,
        loading: updating
    };
};

export default connect(mapStateToProps, mapActionCreators)(OverrideMaterialStore);
