import shipplanService from '../services/shipplanService';
import { SHIPPLAN_PAGE_SIZE } from '../config/config';
import {
    GET_SHIPPLAN,
    GET_SHIPPLAN_FAILURE,
    GET_SHIPPLAN_SUCCESS,
    GET_SHIPPLAN_METADATA,
    GET_SHIPPLAN_METADATA_FAILURE,
    GET_SHIPPLAN_METADATA_SUCCESS,
    SET_SHIPPLAN_FILTER,
    SET_SHIPPLAN_FILTER_FROM_QUERY,
    CLEAR_SHIPPLAN_FILTER,
    SHIPPLAN_TOGGLE_SIDEBAR,
    SHIPPLAN_SET_GROUP_BY,
    SHIPPLAN_SET_SORTING
} from './actionTypes';

export const DEFAULT_PAGE_SIZE = 50;

export const toggleSidebar = () => ({ type: SHIPPLAN_TOGGLE_SIDEBAR });

export const getShipplanSuccess = (response, pageNr) => ({ type: GET_SHIPPLAN_SUCCESS, response, pageNr });

export const getShipplanFailure = error => ({ type: GET_SHIPPLAN_FAILURE, error });

export const getShipplan = (pageNr, signal) => async (dispatch, getState) => {
    const state = getState();
    const { shipplan, unitToggle } = state;
    const { activeFilters, groupBy, sorting, pageNr: currentPageNr } = shipplan;
    const { unitType, priceModel, priceRegion } = unitToggle;

    try {
        dispatch({ type: GET_SHIPPLAN });
        const response = await shipplanService.getShipplan(
            SHIPPLAN_PAGE_SIZE,
            pageNr === undefined ? currentPageNr : pageNr,
            activeFilters,
            sorting,
            groupBy,
            unitType,
            priceModel,
            priceRegion,
            signal
        );
        dispatch(getShipplanSuccess(response, pageNr));
    } catch (err) {
        dispatch(getShipplanFailure(err));
    }
};

export const getShipplanMetaDataSuccess = metaData => ({ type: GET_SHIPPLAN_METADATA_SUCCESS, metaData });

export const getShipplanMetaDataFailure = error => ({ type: GET_SHIPPLAN_METADATA_FAILURE, error });

export const getShipplanMetaData = () => async dispatch => {
    try {
        dispatch({ type: GET_SHIPPLAN_METADATA });
        const metaData = await shipplanService.getShipplanMetaData();
        dispatch(getShipplanMetaDataSuccess(metaData));
    } catch (err) {
        dispatch(getShipplanMetaDataFailure(err));
    }
};

export const setFilter = filter => ({ type: SET_SHIPPLAN_FILTER, filter });
export const clearFilter = () => ({ type: CLEAR_SHIPPLAN_FILTER });
export const setFilterFromQuery = filter => ({ type: SET_SHIPPLAN_FILTER_FROM_QUERY, filter });
export const setGroupBy = groupBy => ({ type: SHIPPLAN_SET_GROUP_BY, groupBy });
export const setSorting = sorting => ({ type: SHIPPLAN_SET_SORTING, sorting });
