import React from 'react';
import { bool, func, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import './ViewShipplanSidebar.scss';
import GroupByContainer from '../GroupBy/GroupByContainer';
import FilterGroupContainer from '../Filter/FilterGroupContainer';
import UnitToggleContainer from '../UnitToggle/UnitToggleContainer';
import ExportShipplanViewButtonContainer from '../ExportShipplanViewButton/ExportShipplanViewButtonContainer';

const ViewShipplanSidebar = ({ open, toggleSidebar, fixedRegion, getShipplan }) => (
    <div className="ViewShipplanSidebar">
        <div className="ViewShipplanSidebar__header">
            <button className="ViewShipplanSidebar__toggle" type="button" tabIndex={0} onClick={toggleSidebar}>
                <FontAwesomeIcon icon={open ? faAngleDoubleLeft : faAngleDoubleRight} />
            </button>
            {open && <h2>Settings</h2>}
        </div>
        {open && (
            <div className="ViewShipplanSidebar__content">
                <ExportShipplanViewButtonContainer />
                <UnitToggleContainer fixedRegion={fixedRegion} onToggle={getShipplan} />
                <GroupByContainer />
                <FilterGroupContainer />
            </div>
        )}
    </div>
);

ViewShipplanSidebar.propTypes = {
    toggleSidebar: func.isRequired,
    getShipplan: func.isRequired,
    fixedRegion: string,
    open: bool
};

export default ViewShipplanSidebar;
