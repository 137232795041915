import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getSeason } from '../../actions/season';
import ShipplanStep from './ShipplanStep';

const mapDispatchToProps = {
    getSeason
};

const mapStateToProps = ({ season }) => ({ season: season.season });

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ShipplanStep);
