import React from 'react';
import { number, arrayOf, shape, string, bool } from 'prop-types';
import qs from 'qs';
import { getToken } from '../../api/api';

const ExportShipplanViewButton = ({ rows, activeFilters, sorting, groupBy, unitType, priceRegion, priceModel }) => {
    if (!rows) {
        return null;
    }

    const queryParams = {
        filter: activeFilters,
        sorting,
        groupBy,
        unitType,
        priceRegion,
        priceModel,
        Authorization: `Bearer ${getToken()}`
    };
    const url = `${process.env.REACT_APP_API_ENDPOINT}/seasons/current/shipplan/download/custom?${qs.stringify(
        queryParams
    )}`;
    const downloadLabel = `Export ${rows} item${rows > 1 ? 's' : ''}`;

    return (
        <a href={url} className="Button" download>
            {downloadLabel}
        </a>
    );
};

ExportShipplanViewButton.propTypes = {
    rows: number,
    activeFilters: shape({}),
    sorting: arrayOf(
        shape({
            id: string,
            desc: bool
        })
    ),
    groupBy: string,
    unitType: string,
    priceRegion: string,
    priceModel: string
};

export default ExportShipplanViewButton;
