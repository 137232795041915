import { connect } from 'react-redux';
import { createPartnerProposals } from '../../actions/partnerProposals';
import CreatePartnerProposalsStep from './CreatePartnerProposalsStep';

const mapDispatchToProps = {
    createPartnerProposals
};

const mapStateToProps = ({
    partnerProposals,
    season: {
        season: { actions, status }
    }
}) => ({
    error: partnerProposals.createPartnerProposalsError,
    loading: partnerProposals.createPartnerProposalsLoading,
    actions,
    seasonStatus: status
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePartnerProposalsStep);
