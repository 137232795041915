import get from 'lodash/get';
import {
    CREATE_PARTNER_PROPOSALS,
    CREATE_PARTNER_PROPOSALS_FAILURE,
    CREATE_PARTNER_PROPOSALS_SUCCESS,
    APPLY_PARTNER_PROPOSALS,
    APPLY_PARTNER_PROPOSALS_FAILURE,
    APPLY_PARTNER_PROPOSALS_SUCCESS,
    UPLOAD_PARTNER_PROPOSAL_SUCCESS,
    UPLOAD_PARTNER_PROPOSAL_FAILURE
} from '../actions/actionTypes';
import createReducer from './createReducer';

export const initialState = {
    createPartnerProposalsError: null,
    createPartnerProposalsSuccess: false,
    createPartnerProposalsLoading: false,
    applyPartnerProposalsError: null,
    applyPartnerProposalsSuccess: false,
    applyPartnerProposalsLoading: false,
    uploadPartnerProposalError: undefined
};

const ACTION_HANDLERS = {
    [CREATE_PARTNER_PROPOSALS]: () => ({
        createPartnerProposalsSuccess: false,
        createPartnerProposalsError: undefined,
        createPartnerProposalsLoading: true
    }),

    [CREATE_PARTNER_PROPOSALS_FAILURE]: (state, { error }) => ({
        createPartnerProposalsError: error,
        createPartnerProposalsLoading: false
    }),

    [CREATE_PARTNER_PROPOSALS_SUCCESS]: () => ({
        createPartnerProposalsSuccess: true,
        createPartnerProposalsLoading: false
    }),

    [APPLY_PARTNER_PROPOSALS]: () => ({
        applyPartnerProposalsSuccess: false,
        applyPartnerProposalsError: undefined,
        applyPartnerProposalsLoading: true
    }),

    [APPLY_PARTNER_PROPOSALS_FAILURE]: (state, { error }) => ({
        applyPartnerProposalsError: error,
        applyPartnerProposalsLoading: false
    }),

    [APPLY_PARTNER_PROPOSALS_SUCCESS]: () => ({ applyPartnerProposalsSuccess: true, applyPartnerProposalsLoading: false }),

    [UPLOAD_PARTNER_PROPOSAL_SUCCESS]: () => ({
        uploadPartnerProposalError: undefined
    }),

    [UPLOAD_PARTNER_PROPOSAL_FAILURE]: (state, { error }) => ({
        uploadPartnerProposalError: error
    })
};

export default createReducer(initialState, ACTION_HANDLERS);

export const getUploadError = state => get(state, 'partnerProposals.uploadPartnerProposalError', {});
