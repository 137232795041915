import { connect } from 'react-redux';
import { overrideMaterial, overrideDelete } from '../../actions/override';
import { isUpdating } from '../../reducers/override';
import OverrideMaterial from './OverrideMaterial';

const mapActionCreators = {
    overrideMaterial,
    overrideDelete
};

const mapStateToProps = (state, ownProps) => {
    const { material, disabled } = ownProps;
    const updating = isUpdating(state, material);

    return {
        disabled: disabled || updating,
        loading: updating
    };
};

export default connect(mapStateToProps, mapActionCreators)(OverrideMaterial);
