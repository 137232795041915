import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import { editSeason } from '../../actions/season';
import withSeason from '../../hoc/withSeason';
import EditSeason from './EditSeason';

const mapDispatchToProps = {
    editSeason
};

const mapStateToProps = ({ season: { season, editSeasonError, editSeasonLoading, editSeasonSuccess } }) => ({
    error: editSeasonError,
    loading: editSeasonLoading,
    success: editSeasonSuccess,
    currentSeason: season,
    actions: get(season, 'actions', [])
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withSeason)(EditSeason);
