import request from '../api/api';

const url = `${process.env.REACT_APP_API_ENDPOINT}/seasons/current/partner-proposals`;

const create = () => request({ method: 'POST', url: `${url}/create` });

const apply = () => request({ method: 'POST', url: `${url}/apply` });

export default {
    apply,
    create
};
