import request from '../api/api';

const current = () =>
    request({
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/seasons/current`
    });

const deleteFile = endpoint =>
    request({
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`
    });

const patch = fields =>
    request({
        method: 'PATCH',
        data: Object.entries(fields).map(field => ({
            op: 'replace',
            path: `/${field[0]}`,
            value: field[1]
        })),
        url: `${process.env.REACT_APP_API_ENDPOINT}/seasons/current`
    });

const post = data =>
    request({
        method: 'POST',
        data,
        url: `${process.env.REACT_APP_API_ENDPOINT}/seasons`
    });

const syncPrices = () =>
    request({
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/seasons/current/prices/sync`
    });

export default {
    current,
    deleteFile,
    patch,
    post,
    syncPrices
};
