import React from 'react';
import { string, arrayOf, func, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import './InvalidOverrides.scss';
import Button from '../Button/Button';
import ConfirmButton from '../ConfirmButton/ConfirmButton';

const InvalidOverrides = ({ invalidOverrides, invalidOverridesAction, deletingInvalidOverrides, onClose, onDelete }) =>
    invalidOverrides.length ? (
        <div className="InvalidOverrides">
            {invalidOverrides.length > 1 ? (
                <p>
                    Some manual overrides conflict with the latest action you are trying to perform. In order to continue you
                    can delete all conflicting overrides below <strong>and retry {invalidOverridesAction}</strong>.
                </p>
            ) : (
                <p>
                    A manual override is conflicting with the latest action you are trying to perform. In order to continue
                    you can delete the conflicting override below <strong>and retry {invalidOverridesAction}</strong>.
                </p>
            )}
            <ul className="InvalidOverrides__list">
                {invalidOverrides.map(material => (
                    <li key={material} className="InvalidOverrides__material">
                        <FontAwesomeIcon icon={faExclamationTriangle} /> {material}
                    </li>
                ))}
            </ul>
            <Button className="Button__inverted" onClick={onClose}>
                Cancel
            </Button>{' '}
            <ConfirmButton
                onClick={onDelete}
                loading={deletingInvalidOverrides}
                label={invalidOverrides.length > 1 ? 'Delete manual overrides' : 'Delete manual override'}
                confirmLabel={
                    invalidOverrides.length > 1 ? 'Really delete manual overrides?' : 'Really delete manual override?'
                }
            />
        </div>
    ) : null;

InvalidOverrides.propTypes = {
    onClose: func.isRequired,
    onDelete: func.isRequired,
    invalidOverridesAction: string,
    invalidOverrides: arrayOf(string),
    deletingInvalidOverrides: bool
};
InvalidOverrides.defaultProps = {
    invalidOverrides: [],
    deletingInvalidOverrides: false
};

export default InvalidOverrides;
