import {
    UNIT_TOGGLE_SET_UNIT_TYPE,
    UNIT_TOGGLE_SET_PRICE_REGION,
    UNIT_TOGGLE_SET_PRICE_MODEL
} from '../actions/actionTypes';
import createReducer from './createReducer';

export const initialState = {
    unitType: 'quantity',
    priceRegion: undefined,
    priceModel: undefined
};

const ACTION_HANDLERS = {
    [UNIT_TOGGLE_SET_UNIT_TYPE]: (state, { unitType }) => {
        if (unitType === 'quantity') {
            return { unitType, priceRegion: undefined, priceModel: undefined };
        }
        // if unitType was previously 'quantity', set default values for region and model
        if (state.unitType === 'quantity') {
            return { unitType, priceRegion: 'netherlands', priceModel: 'retail' };
        }
        return {
            unitType
        };
    },
    [UNIT_TOGGLE_SET_PRICE_REGION]: (state, { priceRegion }) => ({ priceRegion }),
    [UNIT_TOGGLE_SET_PRICE_MODEL]: (state, { priceModel }) => ({ priceModel })
};

export default createReducer(initialState, ACTION_HANDLERS);
