import request from '../api/api';

const getSignedUrl = (name, type) =>
    request({
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/get-signed-url?name=${name}&type=${type}`
    });

const uploadFileOnSignedUrl = (url, file) =>
    request({
        method: 'PUT',
        url,
        file,
        authorization: false,
        headers: {
            'Content-Type': file.type
        }
    });

const post = (endpoint, name) =>
    request({
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}${endpoint}?name=${name}`
    });

export default {
    getSignedUrl,
    uploadFileOnSignedUrl,
    post
};
