import seasonService from '../services/seasonService';
import {
    GET_SEASON,
    GET_SEASON_FAILURE,
    GET_SEASON_SUCCESS,
    SET_SEASON_FROM_SOCKET,
    NEW_SEASON,
    NEW_SEASON_SUCCESS,
    NEW_SEASON_FAILURE,
    EDIT_SEASON,
    EDIT_SEASON_SUCCESS,
    EDIT_SEASON_FAILURE
} from './actionTypes';

export const getSeasonFailure = error => ({ type: GET_SEASON_FAILURE, error });

export const getSeasonSuccess = season => ({ type: GET_SEASON_SUCCESS, season });
export const setSeasonFromSocket = season => ({ type: SET_SEASON_FROM_SOCKET, season });

export const getSeason = () => async dispatch => {
    const action = { type: GET_SEASON };
    try {
        dispatch(action);
        const response = await seasonService.current();

        dispatch(getSeasonSuccess(response.data));
        return response.data;
    } catch (err) {
        dispatch(getSeasonFailure(err.message));
        throw err;
    }
};

export const newSeasonSuccess = () => ({ type: NEW_SEASON_SUCCESS });
export const newSeasonFailure = (error, errorMeta) => ({ type: NEW_SEASON_FAILURE, error, errorMeta });

export const newSeason = data => async dispatch => {
    const action = { type: NEW_SEASON };
    try {
        dispatch(action);
        await seasonService.post(data);

        dispatch(newSeasonSuccess());
    } catch (err) {
        dispatch(newSeasonFailure(err.message, err.meta));
        throw err;
    }
};

export const editSeasonSuccess = () => ({ type: EDIT_SEASON_SUCCESS });
export const editSeasonFailure = (error, errorMeta = null) => ({ type: EDIT_SEASON_FAILURE, error, errorMeta });

export const editSeason = data => async dispatch => {
    const action = { type: EDIT_SEASON };
    try {
        dispatch(action);
        await seasonService.patch(data);

        dispatch(editSeasonSuccess());
    } catch (err) {
        dispatch(editSeasonFailure(err.message, err.meta));
        throw err;
    }
};
