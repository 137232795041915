import { connect } from 'react-redux';
import UnitCell from './UnitCell';

const mapActionCreators = {};

const mapStateToProps = ({ shipplan: { groupBy, priceModel, unitType, priceRegion } }) => ({
    groupBy,
    priceModel,
    unitType,
    priceRegion
});

export default connect(mapStateToProps, mapActionCreators)(UnitCell);
