import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { arrayOf, string, shape, func, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import qs from 'qs';
import SourceFile, { fileTypes } from '../SourceFile/SourceFile';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import { getToken } from '../../api/api';
import './PartnerProposals.scss';
import PartnerProposalsList from './PartnerProposalsList';

const PartnerProposals = ({
    actions,
    partnerProposals,
    getSeason,
    applyPartnerProposals,
    applyPartnerProposalsLoading,
    season,
    uploadPartnerProposalSuccess,
    uploadPartnerProposalFailure
}) => (
    <Container>
        <Row>
            <Col xs={{ span: 8, offset: 2 }}>
                <h1>PARTNER PROPOSALS</h1>
                {actions.includes('managePartnerProposals') && (
                    <Fragment>
                        <section>
                            <h3>Download partnerfile</h3>
                            <p>
                                Here you can download all proposals as excel sheets combined in a zip archive so they can be
                                send to the partners. When returned they can be uploaded below. If all files are uploaded
                                they can be applied at once.
                            </p>
                            <a
                                href={`${
                                    process.env.REACT_APP_API_ENDPOINT
                                }/seasons/current/partner-proposals?${qs.stringify({
                                    Authorization: `Bearer ${getToken()}`
                                })}`}
                                download
                                className="Button"
                            >
                                Download partner proposals (.zip)
                            </a>
                            <br />
                            <em>(This can take a few seconds)</em>
                        </section>
                        <section>
                            <h3>Upload partnerfile</h3>
                            <div className="PartnerProposals__upload">
                                <SourceFile
                                    getSeason={getSeason}
                                    endpoint="/seasons/current/partner-proposals"
                                    title="Partner proposal"
                                    type={fileTypes.XLSX}
                                    resetAfterUpload
                                    onSuccess={uploadPartnerProposalSuccess}
                                    onError={(name, error) => {
                                        uploadPartnerProposalFailure(error);
                                    }}
                                />
                            </div>
                        </section>
                        <section>
                            <h3>Received partnerfiles</h3>
                            <PartnerProposalsList partnerProposals={partnerProposals} />
                        </section>
                        <section>
                            <p>
                                If all partnerfiles are uploaded they can be applied at once. This will insert all overrides,
                                unlock the season and therefore allow you to change the overrides again.
                            </p>
                            <ConfirmButton
                                onClick={applyPartnerProposals}
                                disabled={!actions.includes('applyPartnerProposals')}
                                loading={applyPartnerProposalsLoading}
                                label="Apply partner proposals"
                                confirmLabel="Really apply partner proposals?"
                            />
                        </section>
                    </Fragment>
                )}

                {season.status === 'proposalsApplied' && (
                    <Fragment>
                        <p className="PartnerProposals__success">
                            <FontAwesomeIcon icon={faCheckCircle} />
                            &nbsp; Proposals applied succesfully
                        </p>
                        <p>You can now override quantities again and download partner confirmation files</p>
                        <Link to="/create-shipplan" className="Button">
                            Continue
                        </Link>
                    </Fragment>
                )}
            </Col>
        </Row>
    </Container>
);

PartnerProposals.propTypes = {
    actions: arrayOf(string).isRequired,
    getSeason: func.isRequired,
    applyPartnerProposals: func.isRequired,
    applyPartnerProposalsLoading: bool,
    partnerProposals: arrayOf(
        shape({
            partnerName: string,
            status: string
        })
    ),
    season: shape({
        name: string,
        actions: arrayOf(string)
    }),
    uploadPartnerProposalSuccess: func.isRequired,
    uploadPartnerProposalFailure: func.isRequired
};

PartnerProposals.defaultProps = {
    partnerProposals: []
};

export default PartnerProposals;
