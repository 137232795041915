import React from 'react';
import { any, bool, func, string, node, oneOfType } from 'prop-types';
import './Checkbox.scss';

const CheckBox = ({ label, onChange, id, isChecked }) => (
    <label htmlFor={id} className="checkbox-container">
        <input id={id} type="checkbox" onChange={onChange} checked={isChecked} />
        <span className="checkmark" />
        <span className="checklabel">{label}</span>
    </label>
);

CheckBox.propTypes = {
    label: oneOfType([string, node]),
    onChange: func,
    id: any,
    isChecked: bool
};

CheckBox.defaultProps = {
    isChecked: false,
    label: ''
};

export default CheckBox;
