import React from 'react';
import './Auth.scss';

export default () => (
    <div className="unauthorized">
        <h1>OKURU</h1>
        <h2>
            YOU ARE NOT AUTHORIZED TO ACCESS THIS TOOL, PLEASE SUBMIT A TICKET TO NIKE NOW TO BE ADDED TO THE CORRECT USER
            GROUP
        </h2>
    </div>
);
