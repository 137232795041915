import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import { newSeason } from '../../actions/season';
import withSeason from '../../hoc/withSeason';
import NewSeason from './NewSeason';

const mapDispatchToProps = {
    newSeason
};

const mapStateToProps = ({ season: { season, newSeasonError, newSeasonLoading, newSeasonSuccess } }) => ({
    error: newSeasonError,
    loading: newSeasonLoading,
    success: newSeasonSuccess,
    currentSeason: season,
    actions: get(season, 'actions', [])
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withSeason)(NewSeason);
