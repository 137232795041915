import React from 'react';
import { NavLink } from 'react-router-dom';
import { string, arrayOf } from 'prop-types';
import { Step } from '../Stepper/Stepper';
import StepIndicator from '../StepIndicator/StepIndicator';

const StartNewSeasonStep = ({ actions }) => (
    <Step title="Start new season" indicator={<StepIndicator state="idle" />} last>
        {actions.includes('destroySeason') && (
            <NavLink to="/season/new" className="new-season Button Button__inverted" activeClassName="Button-active">
                Start new season
            </NavLink>
        )}
    </Step>
);

StartNewSeasonStep.propTypes = {
    actions: arrayOf(string)
};

export default StartNewSeasonStep;
