import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import { getShipplan, setFilterFromQuery, setSorting, setGroupBy } from '../../actions/shipplan';
import withQueryParams from '../../hoc/withQueryParams';
import withSeason from '../../hoc/withSeason';
import ViewShipplan from './ViewShipplan';

const mapDispatchToProps = {
    getShipplan,
    setFilterFromQuery,
    setGroupBy,
    setSorting
};

const mapStateToProps = ({ shipplan, season }, { match }) => ({
    actions: get(season, 'season.actions', []),
    material: match.params.material,
    pending: shipplan.getShipplanPending,
    items: shipplan.items,
    pages: shipplan.pages,
    activeFilters: shipplan.activeFilters,
    sidebarOpen: shipplan.sidebarOpen,
    sorting: shipplan.sorting,
    groupBy: shipplan.groupBy,
    unitType: shipplan.unitType,
    pageNr: shipplan.pageNr
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withQueryParams, withSeason)(ViewShipplan);
