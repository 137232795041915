import {
    GET_SHIPPLAN,
    GET_SHIPPLAN_FAILURE,
    GET_SHIPPLAN_SUCCESS,
    GET_SHIPPLAN_METADATA,
    GET_SHIPPLAN_METADATA_FAILURE,
    GET_SHIPPLAN_METADATA_SUCCESS,
    SET_SHIPPLAN_FILTER,
    SET_SHIPPLAN_FILTER_FROM_QUERY,
    GET_MATERIAL_SUCCESS,
    CLEAR_SHIPPLAN_FILTER,
    SHIPPLAN_TOGGLE_SIDEBAR,
    SHIPPLAN_SET_GROUP_BY,
    SHIPPLAN_SET_SORTING,
    OVERRIDE_SUCCESS
} from '../actions/actionTypes';
import { groupBy as shipplanGroupBy } from '../components/GroupBy/GroupBy';
import createReducer from './createReducer';

export const initialState = {
    items: undefined,
    getShipplanPending: false,
    getShipplanSuccess: false,
    getShipplanError: undefined,
    getShipplanMetaDataPending: false,
    getShipplanMetaDataSuccess: false,
    getShipplanMetaDataError: undefined,
    metaData: undefined,
    pages: 0,
    rows: 0,
    nextPage: 0,
    pageNr: 0,
    activeFilters: {},
    sidebarOpen: true,
    sorting: undefined,
    groupBy: shipplanGroupBy.MATERIAL,
    unitType: undefined,
    priceRegion: undefined,
    priceModel: undefined
};

const ACTION_HANDLERS = {
    [SHIPPLAN_SET_SORTING]: (state, { sorting }) => ({ sorting }),
    [SHIPPLAN_SET_GROUP_BY]: (state, { groupBy }) => ({ groupBy }),
    [SHIPPLAN_TOGGLE_SIDEBAR]: state => ({ sidebarOpen: !state.sidebarOpen }),
    [GET_SHIPPLAN]: () => ({ getShipplanPending: true, getShipplanSuccess: false, getShipplanError: undefined }),
    [GET_SHIPPLAN_FAILURE]: (state, { error }) => ({
        getShipplanPending: false,
        getShipplanError: error
    }),
    [GET_SHIPPLAN_SUCCESS]: (state, { response, pageNr }) => ({
        getShipplanPending: false,
        getShipplanSuccess: true,
        getShipplanError: undefined,
        items: response.items,
        pageNr,
        unitType: response.unitType,
        priceRegion: response.priceRegion,
        priceModel: response.priceModel,
        nextPage: response.nextPage,
        rows: response.rows,
        pages: response.pages
    }),
    [GET_MATERIAL_SUCCESS]: (state, { material }) => ({
        unitType: material.unitType,
        priceRegion: material.priceRegion,
        priceModel: material.priceModel
    }),
    [GET_SHIPPLAN_METADATA]: () => ({ getShipplanMetaDataPending: true }),
    [GET_SHIPPLAN_METADATA_FAILURE]: (state, { error }) => ({
        getShipplanMetaDataSuccess: false,
        getShipplanMetaDataError: error,
        getShipplanMetaDataPending: false
    }),
    [GET_SHIPPLAN_METADATA_SUCCESS]: (state, { metaData }) => ({
        getShipplanMetaDataSuccess: true,
        getShipplanMetaDataError: undefined,
        getShipplanMetaDataPending: false,
        metaData
    }),
    [SET_SHIPPLAN_FILTER]: (state, action) => ({ activeFilters: { ...state.activeFilters, ...action.filter } }),
    [SET_SHIPPLAN_FILTER_FROM_QUERY]: (state, action) => ({ activeFilters: action.filter }),
    [CLEAR_SHIPPLAN_FILTER]: () => ({ activeFilters: initialState.activeFilters }),
    [OVERRIDE_SUCCESS]: ({ items = [] }, { materialInfo }) => ({
        items: items.map(item => (materialInfo.Material === item.Material ? materialInfo : item))
    })
};

export default createReducer(initialState, ACTION_HANDLERS);
