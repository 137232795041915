import React from 'react';
import { Switch, Route, Link, NavLink } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import './Documentation.scss';

const Home = () => (
    <p>
        Okuru lets you create a ship plan based on information like a buyplan and orderbook. To create a basic ship plan you
        can go to the <Link to="/create-shipplan">Create Shipplan</Link> page and upload the required documents.
    </p>
);

const Glossary = () => (
    <div>
        <h2>Glossary</h2>
        <h3>AA Styles</h3>
        <p>
            Always available, this material does not need to be in the orderbook to be fulfilled by Okuru&#39;s distribution
            logic. A style can be marked as AA by adding it to the AA / Replenishment file during the{' '}
            <Link to="/documentation/file-imports">file imports</Link> phase of a season.
        </p>
        <h3>Buy</h3>
        <p>Number of units of a product a store/partner committed to buy</p>
        <h3>Buy plan</h3>
        <p>File containing all information about the partner commits per product, this file can be exported from Mancala</p>
        <h3>Commit</h3>
        <p>same as Buy</p>
        <h3>Distribution</h3>
        <p>Process of distributing the produced units across the stores that committed to buy that product</p>
        {/* eslint-disable-next-line */}
        <a id="flow" />
        <h3>Flow</h3>
        <p>
            A season is divided in three periods called flows. These can be configured on the{' '}
            <Link to="/season/edit">Edit season</Link> page by defining four dates.
        </p>
        <ul>
            <li>
                Flow 1 starts on the <code>Season start date</code> and ends the day before <code>Start flow 2</code>
            </li>
            <li>
                Flow 2 starts on <code>Start flow 2</code> and ends the day before <code>Start flow 3</code>
            </li>
            <li>
                Flow 3 starts on <code>Start flow 3</code> and ends on <code>Season end date</code>
            </li>
        </ul>
        <h3>Global minimum</h3>
        <p>
            If during the distribution process a there is not enough supply to fulfil a store&#39;s minimum buy. The
            remaining supply will be given only if it is 9 or higher.
        </p>

        <h3>Mancala</h3>
        <p>Application that consolidates partner buy plans in to one big buy plan for OneNSP. </p>
        <h3>Okuru</h3>
        <p>The name of this application. Previously named - and sometimes still referred to as - &#39;Ship plan&#39;</p>
        <h3>Orderbook (file)</h3>
        <p>File exported from SAP containing all information about the produced products</p>
        <h3>Orderbook (column)</h3>
        <p>
            The amount of produced products that have a Requested Delivery Date that fall in a{` `}
            <a href="/documentation/glossary#flow">Flow</a> range
        </p>
        <h3>Replenishment pool</h3>
        <p>
            The sum of the units in the replenishment column for a material. Increased by amount of unassigned units. Within
            a single flow.
        </p>
        <h3>Season</h3>
        <p>
            See <Link to="/documentation/season">Season</Link>.
        </p>
        <h3>Shipplan</h3>
        <p>To be defined...</p>
        <h3>Variance (per flow)</h3>
        <p>Orderbook coverage - Manual override. If there is no manual override, then: Orderbook - Proposal</p>
        <h3>Variance (full seaon)</h3>
        <p>Sum of Flow 1,2,3 variances.</p>
    </div>
);

const Season = () => (
    <div>
        <h2>Season</h2>
        <p>
            The application knows start and end dates for the season, but within that season the user is able to set the
            start date for flow 2 and flow 3, dates must alway be in chronological order (start season - flow 2 start - flow
            3 start - end season). After one of these dates are updated, the sizing/distribution process will be triggered
            again.
        </p>
        <h3>New season</h3>
        <p>
            If you want to start working on a new season you can click the &#39;Start new season&#39; button. Here you can
            configure the name and dates related to the new season. All dates should be in chronological order and the season
            name should have a valid format (SP/SU/FA/HO followed by a two digit year).
        </p>
        <p>
            <em>Keep in mind that this step is irreversible and will empty the whole database!</em>
        </p>
        <h3>Edit current season</h3>
        <p>
            If you want to change the name or dates of the current season you can click the &#39;Edit current season&#39;
            button. All dates should be in chronological order and the season name should have a valid format (SP/SU/FA/HO
            followed by a 2 digit year). Remember that this actions <em>could</em> result in a conflict with the manual
            overrides. If so, the application will notify you and give you the option to remove all conflicting overrides.
        </p>
    </div>
);

const FileImports = () => (
    <div>
        <h2>File imports</h2>
        <p>
            The ship plan application relies on several files being uploaded before the sizing and proposal calculation can
            start, the files required are listed below.
        </p>
        <h3>Import Mancala BuyPlan</h3>
        <p>
            At the start of a season, the user can upload a BuyPlan exported from the Export Seasons screen in{' '}
            <a href="https://buyplan.dsmplanning.nikecloud.com/export-seasons" rel="noopener noreferrer" target="_blank">
                Mancala
            </a>{' '}
            as a base for the Shipplan.
        </p>
        <a href="/downloads/buyplan.csv" className="Button">
            Buyplan template (CSV)
        </a>
        <h3>Import SAP Contract file (Orderbook)</h3>
        <p>
            After the Mancala BuyPlan file is imported, the application knows what the partners committed to buy, the next
            step is to let the application know what was actually produced. This is done by uploading the SAP Contract file.
            Before you upload the SAP Contract file, please remove the top 2 and the bottom 2 lines (subtotals);
        </p>
        <a href="/downloads/orderbook.xlsx" className="Button">
            Orderbook template (XLSX)
        </a>
        <h3>Import AA / Replenishment file</h3>
        <p>
            A file that contains AA / Replenishment information per material can be uploaded. This will only
            &lsquo;flag&rsquo; that materials for now. Later when the application actually proposes a quantity to the user,
            this information will be used for calculations.
        </p>
        <a href="/downloads/aa_and_replen.xlsx" className="Button">
            AA &amp; Replen template (XLSX)
        </a>
        <h3>Import Store Rank file</h3>
        <p>
            A file that contains store information per division category level can be uploaded. Relevant information in this
            file is the rank of a store and the minimum buy per material (on division-category level). Currently we only
            store this information, later when the application actually proposes a quantity to the user, this information
            will be used for calculations.
        </p>
        <a href="/downloads/store_ranking.xlsx" className="Button">
            Store Ranking template (XLSX)
        </a>
        <h3>Import Carry over file</h3>
        <p>
            A file that contains carry over information per store per material can be uploaded. This file contains the
            phaseIn and phaseOut date per material store combination together with the quantity. Currently we only store this
            information, later when the application actually proposes a quantity to the user, this information will be used
            for calculations.
        </p>
        <a href="/downloads/carryover.xlsx" className="Button">
            Carryover template (XLSX)
        </a>
    </div>
);

const DistributionProposal = () => (
    <div>
        <h2>Distribution and proposal</h2>
        <h3>Distribution across partners</h3>
        <p>
            The application will divide the produced units amongst the partners. Never will a partner get more units assigned
            than they originally committed to buy. If there are any leftovers during the process of rounding down (since we
            cannot ship 0.9 product), the leftovers will be divided as honest as possible.
        </p>
        <h4>Sample:</h4>
        <p>
            After applying all the rules partner A should get 19,9 units and partner B 20,01. Both partners should get a
            whole units, the final results are: partner A 20 units and Partner B 20 units. Rounding rule:
        </p>
        <ul>
            <li>If the number is greater or equal with 0.5 we round up</li>
            <li>If the number is less than 0.5 we round down</li>
        </ul>
        <h3>Distribution across flows</h3>
        <p>
            If the supply in a flow is higher than the demand the remaining units will be carried over to the next flow and
            added to that supply.
        </p>
        <p>
            If there is a shortage in a flow and the units are being produced is a later flow, the system will propose the
            units in the later flow.
        </p>
        <h3>Business rule: store ranks and minimum buys</h3>
        <p>
            This business rule makes sure a store doesn&#39;t get a quantity lower than the minimum buy*. To determine which
            store should get the minimum first, we prioritize based on the rank, therefore store ranks should all be
            different.
        </p>
        <p>
            If during the distribution process there is not enough supply to fulfil a store&#39;s minimum buy. The remaining
            supply will be given only if it is 9 or higher. This number is referred to as the global minimum. If the supply
            is lower than the global minimum, what is left over will be distributed amongst the stores that have their
            minimum fulfilled. This distribution happens according to the ratio of the original buy.
        </p>
        <p>
            &#42;The exception here, is when the original buy was already lower than than the minimum buy, in this case we
            treat the original buy as minimum.
        </p>
        <h3>Business rule: carry over and AA styles</h3>
        <p>
            This business rule spreads units for a material across initial load in and replenishment. By default all of the
            available units will be load in quantity. The rules are:
        </p>
        <ul>
            <li>Replenishment style + carry over -&gt; minimum buy on load in, rest on replenishment</li>
            <li>Replenishment style + not carry over -&gt; minimum buy on load in, rest on replenishment</li>
            <li>AA Style + carry over -&gt; everything on replenishment</li>
            <li>AA Style + not carry over -&gt; minimum buy on load in, rest on replenishment</li>
        </ul>
        <p>
            &#42;A material is considered carry over if the phase in date is before the season start and the phase out date
            is after the season start.
        </p>
        <h4>AA Styles</h4>
        <p>
            For AA Styles the application doesn&#39;t look in the orderbook to see how much is produced. Any number on the
            buy will be fulfilled during the distribution process. Partner proposals will also be accepted no matter how high
            the request is.
        </p>
        <h3>Business rule: partner proposal file</h3>
        <p>
            Partners can adjust their load in quantities in the partner proposal file. These units will be taken from the
            replenishment pool. If replenishment pools are empty the application tries to take units from the replenishment
            pool of previous flows
        </p>
        <p>
            If the load in requests in partner files exceed the amount of available units, the application distributes with
            the ratio of the additional requests.
        </p>
        <h3>Overrides</h3>
        <p>
            The user is able to override proposals made by the application, on material and material-store level&#42;. The
            application allows overrides to use units that are available in earlier flows. The total amount over overrides
            cannot exceed the amount of produced units. An override can be removed by clicking the{' '}
            <FontAwesomeIcon icon={faLock} /> icon.
        </p>
        <p>
            When material level overrides are higher than the sum of the original buy, the units will be distributed
            according to the original buy ratio of the stores.
        </p>
        <p>
            &#42;Material level overrides can not be made for AA styles. Material-store level overrides for AA style have no
            maximum.
        </p>
    </div>
);

const PartnerProposals = () => (
    <div>
        <h2>Partner proposals</h2>

        <p>Working with the partner propopals takes the following steps to complete</p>

        <h3>1. Creating</h3>
        <p>
            To create the proposal files that can be send to the partners you can click the &#39;create partner
            proposals&#39; button on the &#39;Create ship plan&#39; page. This will lock the season and therefore no
            overrides can be made during this period. When the season is locked and the propopals are created the button
            changes to &#39;manage partner proposals&#39;. Clicking this button will open a page where the proposals can be
            downloaded as a .zip-file.
        </p>

        <h3>2. Download and distribute</h3>
        <p>
            On the &#39;manage partner proposals&#39; page, the partner files can be downloaded as a zip archive. This
            archive contains Excel files that can be sent to each individual partner. In this file they can adjust the amount
            of requested products in the <code>&#39;Flow (1/2/3) Load In Request&#39;</code> columns. A partner can request
            as many products as they want. If the quantity is equal or lower than proposed, this request will be fulfilled.
            However, if the request exceeds the proposed quantity, any leftovers will be distributed across all partners
            based on the ratio of the additional requests.
        </p>

        <h3>3. Uploading</h3>
        <p>
            On the &#39;manage partner proposals&#39; page, the partner files can be uploaded. A list of the partner proposal
            files shows their status. Grey - waiting to be uploaded. Green - uploaded and ready to be applied. The ship plan
            will be not affected until all files are uploaded and step 4 is executed.
        </p>

        <h3>4. Applying</h3>
        <p>
            As soon as all the partner files are uploaded back into the system, the &#39;apply partner proposals&#39; button
            will be enabled. Applying the propopals will create a manual override (<FontAwesomeIcon icon={faLock} />) for
            every material-store line in the ship plan view. This behavior is exactly the same as applying a manual override
            yourself. After applying you can download confirmation files for each partner to inform them about the results.
        </p>
        <p>The season is unlocked now and changes to the ship plan can be made if needed.</p>
    </div>
);

const Export = () => (
    <div>
        <h2>Export</h2>
        <h3>Export ship plan</h3>
        <p>
            If all the steps towards a final ship plan are completed, the home screen will show an export button. This is
            used to export on Material / store level. The file will contanin information for all partners / stores.
        </p>
        <h3>Export selection</h3>
        <p>
            Op the <Link to="/view-shipplan">View ship plan</Link> page you can export all data with the current filters and
            aggregation applied by clicking the export button in the top left corner. This will download an excel file with 2
            sheets. The applied filters can be found in the second sheet.
        </p>
    </div>
);

const Finance = () => (
    <div>
        <h2>Finance</h2>
        <h3>Price information source</h3>
        <p>
            Prices are fetched from the{' '}
            <a
                href="https://github.nike.com/PDX/style-product-service/wiki/REST-API-v2"
                target="_blank"
                rel="noopener noreferrer"
            >
                style-product-service
            </a>{' '}
            when a buyplan or orderbook is uploaded. You can also manually trigger an update by using the &#39;get latest
            prices&#39; button on the <Link to="/create-shipplan">Create Shipplan</Link> page.
        </p>
        <h3>Unit toggle/filter</h3>
        <p>
            All prices are always displayed in USD. When grouped by Material on the{' '}
            <Link to="/create-shipplan">View ship plan</Link> page you see the retail and wholesale prices defined in the
            Netherlands pricelist.
        </p>
        <h3>Exchange rates</h3>
        <p>
            The current exchange rates can be found on the: <Link to="/exchange-rates">Exchange rates</Link> page. If these
            exchange rates are outdated and/or should be changed, please{' '}
            <Link to="/documentation/support">submit a request</Link>.
        </p>
    </div>
);

const Support = () => (
    <div>
        <h2>Support</h2>
        <p>
            In case you encounter issues with Okuru or have a support request you can report this in Nike Now by selecting
            {` `}
            <code>Mancala (EMEA)</code> as Service. Our support office will find the right knowledge capabilities and/or
            decision makers for the issue and will contact you.
        </p>
        <p>
            <a href="https://niketech.service-now.com/nikenow/?id=nike_incident" target="_blank" rel="noopener noreferrer">
                <img
                    src="https://niketech.service-now.com/nikenow/235fc7911311ba80aa617e276144b001.iix"
                    width="200"
                    alt="Nike Now"
                />
            </a>
            <br />
            If you need help reporting a request you can find a detailed guide{' '}
            <a href="https://nike.box.com/s/nujlcb2tk0mzjs4zt4fl7auebsisuibt">here</a>.
        </p>
    </div>
);

export default function Documentation() {
    return (
        <div className="Documentation">
            <Container>
                <Row>
                    <Col xs={{ span: 8, offset: 4 }}>
                        <h1>DOCUMENTATION</h1>
                    </Col>
                    <Col xs={{ span: 3, offset: 1 }}>
                        <nav className="Documentation__navigation">
                            <NavLink to="/documentation" exact>
                                Home
                            </NavLink>
                            <NavLink to="/documentation/glossary">Glossary</NavLink>
                            <NavLink to="/documentation/season">Season</NavLink>
                            <NavLink to="/documentation/file-imports">File imports</NavLink>
                            <NavLink to="/documentation/distribution-proposal">Distribution and proposal</NavLink>
                            <NavLink to="/documentation/export">Export</NavLink>
                            <NavLink to="/documentation/partner-proposals">Partner proposals</NavLink>
                            <NavLink to="/documentation/finance">Finance</NavLink>
                            <hr />
                            <NavLink to="/documentation/support">Support</NavLink>
                        </nav>
                    </Col>
                    <Col xs={{ span: 8, offset: 0 }}>
                        <Switch>
                            <Route path="/documentation/Glossary" component={Glossary} />
                            <Route path="/documentation/partner-proposals" component={PartnerProposals} />
                            <Route path="/documentation/season" component={Season} />
                            <Route path="/documentation/file-imports" component={FileImports} />
                            <Route path="/documentation/distribution-proposal" component={DistributionProposal} />
                            <Route path="/documentation/export" component={Export} />
                            <Route path="/documentation/finance" component={Finance} />
                            <Route path="/documentation/support" component={Support} />
                            <Route component={Home} />
                        </Switch>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
