import { connect } from 'react-redux';
import { addGlobalError, clearGlobalErrors } from '../../actions/globalError';
import GlobalError from './GlobalError';

const mapStateToProps = ({ globalError }) => ({
    errors: globalError.errors
});
const mapDispatchToProps = {
    addGlobalError,
    clearGlobalErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalError);
