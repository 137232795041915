import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Step } from '../Stepper/Stepper';
import StepIndicator from '../StepIndicator/StepIndicator';

const SeasonStep = ({ season }) => (
    <Step title={`${season.name} Season started`} indicator={<StepIndicator state="completed" />} data-cy="seasonstep">
        {season.actions.includes('editSeason') && (
            <NavLink to="/season/edit" className="edit-season Button Button__inverted" activeClassName="Button-active">
                Edit current season
            </NavLink>
        )}
    </Step>
);

SeasonStep.propTypes = {
    season: shape({
        name: string,
        actions: arrayOf(string)
    })
};
SeasonStep.defaultProps = {
    season: {
        name: undefined,
        actions: []
    }
};

export default SeasonStep;
