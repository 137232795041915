import { connect } from 'react-redux';
import { createPartnerProposals } from '../../actions/partnerProposals';
import StartNewSeasonStep from './StartNewSeasonStep';

const mapDispatchToProps = {
    createPartnerProposals
};

const mapStateToProps = ({
    season: {
        season: { actions }
    }
}) => ({ actions });

export default connect(mapStateToProps, mapDispatchToProps)(StartNewSeasonStep);
