import React, { PureComponent } from 'react';
import { isEmpty, isEqual } from 'lodash';
import qs from 'qs';

function withQueryParams(WrappedComponent) {
    return class extends PureComponent {
        constructor(props) {
            super(props);
            this.setParamsFromState = this.setParamsFromState.bind(this);
            this.setStateFromParams = this.setStateFromParams.bind(this);

            const {
                activeFilters,
                location: { search }
            } = props;
            if (!isEmpty(activeFilters)) {
                this.setParamsFromState();
            } else if (isEmpty(activeFilters) && !isEmpty(search)) {
                this.setStateFromParams(false);
            }
        }

        componentDidUpdate(prevProps) {
            const {
                activeFilters,
                groupBy,
                sorting,
                location: { search }
            } = this.props;

            if (
                !isEqual(prevProps.activeFilters, activeFilters) ||
                !isEqual(prevProps.groupBy, groupBy) ||
                !isEqual(prevProps.sorting, sorting) ||
                search === ''
            ) {
                this.setParamsFromState();
            }
        }

        setParamsFromState() {
            const {
                activeFilters,
                groupBy,
                sorting,
                history: { push }
            } = this.props;
            const search = qs.stringify({ ...activeFilters, groupBy, sorting });

            push({
                search
            });
        }

        setStateFromParams(shouldFetch = true) {
            const {
                location: { search },
                setFilterFromQuery,
                setSorting,
                setGroupBy,
                getShipplan
            } = this.props;
            const queryString = search.substr(1);
            const query = qs.parse(queryString);
            const { groupBy, sorting, ...filters } = query;
            if (filters) {
                setFilterFromQuery(filters);
            }
            if (sorting) {
                setSorting(sorting.map(s => ({ ...s, desc: s.desc === 'true' })));
            }
            if (groupBy) {
                setGroupBy(groupBy);
            }
            if (shouldFetch && (filters || sorting || groupBy)) {
                getShipplan(0);
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };
}

export default withQueryParams;
