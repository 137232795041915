import React from 'react';
import { number, oneOf } from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import './StepIndicator.scss';

export const INDICATOR_COMPLETED = 'completed';
export const INDICATOR_IMPORTING = 'processing';
export const INDICATOR_FAILED = 'failed';
export const INDICATOR_IDLE = 'idle';
export const INDICATOR_WAITING_ON_USER = 'waiting';

const StepIndicator = ({ state, percentage: providedPercentage, sqSize, strokeWidth, totalParts, completedParts }) => {
    let percentage = providedPercentage;
    let label;
    let icon;
    let extraClass;
    switch (state) {
        case INDICATOR_IMPORTING: {
            if (percentage !== undefined) {
                label = `${percentage}%`;
            } else if (totalParts !== undefined && completedParts !== undefined) {
                label = `${completedParts}/${totalParts}`;
            } else {
                extraClass = 'spinning';
            }
            break;
        }
        case INDICATOR_COMPLETED: {
            icon = <FontAwesomeIcon icon={faCheck} size="xs" />;
            break;
        }
        case INDICATOR_FAILED: {
            icon = <FontAwesomeIcon icon={faTimes} size="xs" />;
            break;
        }
        case INDICATOR_WAITING_ON_USER: {
            icon = <FontAwesomeIcon icon={faFileUpload} size="xs" />;
            break;
        }
        default:
    }
    if (!percentage && totalParts !== undefined && completedParts !== undefined) {
        percentage = Math.round((completedParts / totalParts) * 100);
    }

    const radius = sqSize / 2;
    const progressSqSize = sqSize - strokeWidth * 2;
    const progressRadius = (progressSqSize - strokeWidth * 2) / 2;
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    const dashArray = progressRadius * Math.PI * 2;
    const dashOffset = dashArray - (dashArray * (percentage !== undefined ? percentage : 33)) / 100;

    return (
        <svg
            width={sqSize}
            height={sqSize}
            viewBox={viewBox}
            className={cn('StepIndicator', `StepIndicator--state-${state}`, {
                [`StepIndicator--${extraClass}`]: !!extraClass
            })}
        >
            <circle className="StepIndicator__circle-background" cx={sqSize / 2} cy={sqSize / 2} r={radius} />
            {state === INDICATOR_IMPORTING && (
                <circle
                    className="StepIndicator__circle-progress"
                    cx={sqSize / 2}
                    cy={sqSize / 2}
                    r={progressRadius}
                    strokeWidth={`${strokeWidth}px`}
                    transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
                    style={{
                        strokeDasharray: dashArray,
                        strokeDashoffset: dashOffset
                    }}
                />
            )}
            {icon}
            {label && (
                <text className="StepIndicator__circle-text" x="50%" y="50%" dy=".3em" textAnchor="middle">
                    {label}
                </text>
            )}
        </svg>
    );
};

StepIndicator.propTypes = {
    percentage: number,
    totalParts: number,
    completedParts: number,
    sqSize: number,
    strokeWidth: number,
    state: oneOf([INDICATOR_COMPLETED, INDICATOR_IMPORTING, INDICATOR_FAILED, INDICATOR_IDLE, INDICATOR_WAITING_ON_USER])
};

StepIndicator.defaultProps = {
    sqSize: 64,
    strokeWidth: 3,
    state: INDICATOR_IDLE
};

export default StepIndicator;
