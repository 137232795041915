export const AUTH_CHECK_START = 'AUTH_CHECK_START';
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_CLEAR_TOKEN = 'AUTH_CLEAR_TOKEN';
export const AUTH_CHECK_COMPLETE = 'AUTH_CHECK_COMPLETE';
export const AUTH_UNAUTHORIZED = 'AUTH_UNAUTHORIZED';
export const AUTH_CHECK_ERROR = 'AUTH_CHECK_ERROR';

export const GET_SEASON = 'GET_SEASON';
export const GET_SEASON_FAILURE = 'GET_SEASON_FAILURE';
export const GET_SEASON_SUCCESS = 'GET_SEASON_SUCCESS';
export const SET_SEASON_FROM_SOCKET = 'SET_SEASON_FROM_SOCKET';

export const NEW_SEASON = 'NEW_SEASON';
export const NEW_SEASON_FAILURE = 'NEW_SEASON_FAILURE';
export const NEW_SEASON_SUCCESS = 'NEW_SEASON_SUCCESS';

export const EDIT_SEASON = 'EDIT_SEASON';
export const EDIT_SEASON_FAILURE = 'EDIT_SEASON_FAILURE';
export const EDIT_SEASON_SUCCESS = 'EDIT_SEASON_SUCCESS';

export const GET_MATERIAL = 'GET_MATERIAL';
export const GET_MATERIAL_FAILURE = 'GET_MATERIAL_FAILURE';
export const GET_MATERIAL_SUCCESS = 'GET_MATERIAL_SUCCESS';

export const MATERIAL_SET_SORTING = 'MATERIAL_SET_SORTING';

export const GET_SHIPPLAN = 'GET_SHIPPLAN';
export const GET_SHIPPLAN_FAILURE = 'GET_SHIPPLAN_FAILURE';
export const GET_SHIPPLAN_SUCCESS = 'GET_SHIPPLAN_SUCCESS';

export const GET_SHIPPLAN_METADATA = 'GET_SHIPPLAN_METADATA';
export const GET_SHIPPLAN_METADATA_FAILURE = 'GET_SHIPPLAN_METADATA_FAILURE';
export const GET_SHIPPLAN_METADATA_SUCCESS = 'GET_SHIPPLAN_METADATA_SUCCESS';

export const SET_SHIPPLAN_FILTER = 'SET_SHIPPLAN_FILTER';
export const SET_SHIPPLAN_FILTER_FROM_QUERY = 'SET_SHIPPLAN_FILTER_FROM_QUERY';
export const CLEAR_SHIPPLAN_FILTER = 'CLEAR_SHIPPLAN_FILTER';

export const SHIPPLAN_TOGGLE_SIDEBAR = 'SHIPPLAN_TOGGLE_SIDEBAR';

export const SHIPPLAN_SET_GROUP_BY = 'SHIPPLAN_SET_GROUP_BY';
export const SHIPPLAN_SET_SORTING = 'SHIPPLAN_SET_SORTING';

export const UNIT_TOGGLE_SET_UNIT_TYPE = 'UNIT_TOGGLE_SET_UNIT_TYPE';
export const UNIT_TOGGLE_SET_PRICE_REGION = 'UNIT_TOGGLE_SET_PRICE_REGION';
export const UNIT_TOGGLE_SET_PRICE_MODEL = 'UNIT_TOGGLE_SET_PRICE_MODEL';

export const OVERRIDE = 'OVERRIDE';
export const OVERRIDE_FAILURE = 'OVERRIDE_FAILURE';
export const OVERRIDE_SUCCESS = 'OVERRIDE_SUCCESS';

export const SET_INVALID_OVERRIDES = 'SET_INVALID_OVERRIDES';
export const CLEAR_INVALID_OVERRIDES = 'CLEAR_INVALID_OVERRIDES';
export const DELETE_INVALID_OVERRIDES = 'DELETE_INVALID_OVERRIDES';
export const DELETE_INVALID_OVERRIDES_FAILURE = 'DELETE_INVALID_OVERRIDES_FAILURE';
export const DELETE_INVALID_OVERRIDES_SUCCESS = 'DELETE_INVALID_OVERRIDES_SUCCESS';

export const CREATE_PARTNER_PROPOSALS = 'CREATE_PARTNER_PROPOSALS';
export const CREATE_PARTNER_PROPOSALS_FAILURE = 'CREATE_PARTNER_PROPOSALS_FAILURE';
export const CREATE_PARTNER_PROPOSALS_SUCCESS = 'CREATE_PARTNER_PROPOSALS_SUCCESS';

export const APPLY_PARTNER_PROPOSALS = 'APPLY_PARTNER_PROPOSALS';
export const APPLY_PARTNER_PROPOSALS_FAILURE = 'APPLY_PARTNER_PROPOSALS_FAILURE';
export const APPLY_PARTNER_PROPOSALS_SUCCESS = 'APPLY_PARTNER_PROPOSALS_SUCCESS';

export const UPLOAD_PARTNER_PROPOSAL_SUCCESS = 'UPLOAD_PARTNER_PROPOSAL_SUCCESS';
export const UPLOAD_PARTNER_PROPOSAL_FAILURE = 'UPLOAD_PARTNER_PROPOSAL_FAILURE';

export const ADD_GLOBAL_ERROR = 'ADD_GLOBAL_ERROR';
export const CLEAR_GLOBAL_ERRORS = 'CLEAR_GLOBAL_ERRORS';
