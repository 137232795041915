/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import io from 'socket.io-client';
import { func, array } from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './GlobalError.scss';

const socket = io(process.env.REACT_APP_API_ENDPOINT);

export default class GlobalError extends Component {
    static propTypes = {
        addGlobalError: func.isRequired,
        clearGlobalErrors: func.isRequired,
        errors: array
    };

    static defaultProps = {
        errors: []
    };

    componentDidMount() {
        const { addGlobalError } = this.props;
        socket.on('global-error', message => {
            addGlobalError(message);
        });
    }

    componentWillUnmount() {
        socket.removeListener('global-error');
    }

    render() {
        const { errors, clearGlobalErrors } = this.props;

        return (
            <TransitionGroup className="GlobalError" component="ul">
                {errors.map(({ title, timestamp, description }) => (
                    <CSSTransition
                        key={timestamp}
                        timeout={{
                            enter: 500,
                            exit: 300
                        }}
                        classNames="GlobalError__item--animation"
                    >
                        <li className="GlobalError__item">
                            <h3>{title} </h3>
                            <span
                                className="GlobalError__clear"
                                role="button"
                                tabIndex={-1}
                                onClick={() => clearGlobalErrors(timestamp)}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                            <p>{description}</p>
                        </li>
                    </CSSTransition>
                ))}
            </TransitionGroup>
        );
    }
}
