import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import foldableTable from 'react-table/lib/hoc/foldableTable';
import cn from 'classnames';
import { func, bool, array, number, arrayOf, string, shape } from 'prop-types';
import 'react-table/react-table.css';
import { SHIPPLAN_PAGE_SIZE } from '../../config/config';
import getColumnsByGroup from './columns';
import './ViewShipplan.scss';
import ViewShipplanSidebarContainer from './ViewShipplanSidebarContainer';

const Table = foldableTable(ReactTable);
class ViewShipplan extends Component {
    static propTypes = {
        getShipplan: func.isRequired,
        actions: arrayOf(string),
        pending: bool,
        items: array,
        pages: number,
        pageNr: number,
        sidebarOpen: bool,
        setSorting: func,
        sorting: arrayOf(shape({ id: string, desc: bool })),
        // eslint being stupid once again, claims groupBy prop is not used:
        groupBy: string // eslint-disable-line
    };

    static defaultProps = {
        items: []
    };

    state = {};

    static getDerivedStateFromProps(props, state) {
        // We're intentionally not reacting to intermittent changes to whether or not the override action is allowed
        // This would make it very jumpy on updating overrides as the lock icons will dissappear for a second and re-appear,
        // making everything very annoying. Instead, we'll send a disabled prop to the MaterialOverrides containers when
        // overrides are not possible intermittently
        const { actions, groupBy, unitType } = props;
        if (groupBy !== state.groupBy || unitType !== state.unitType) {
            return {
                groupBy,
                unitType,
                columns: getColumnsByGroup({
                    group: groupBy,
                    override: actions.includes('override'),
                    unitType
                })
            };
        }

        return null;
    }

    componentDidMount() {
        const { getShipplan } = this.props;
        getShipplan(0);
    }

    handlePageChange = page => {
        const { getShipplan } = this.props;
        if (this.abortController) this.abortController.abort();

        this.abortController = new AbortController();

        getShipplan(page, this.abortController.signal);
    };

    handleSorting = sort => {
        const { setSorting, getShipplan } = this.props;
        setSorting(sort);
        getShipplan(0);
    };

    render() {
        const { items, pages, pending, sidebarOpen, sorting, actions, pageNr } = this.props;
        const { columns } = this.state;
        const disabled = !actions.includes('override');

        return (
            <div className={cn('view-shipplan', { 'view-shipplan--sidebar-open': sidebarOpen })}>
                <div className="view-shipplan__sidebar">
                    <ViewShipplanSidebarContainer />
                </div>
                <div className="view-shipplan__table">
                    <Table
                        data={items.map(item => ({ ...item, disabled }))}
                        columns={columns}
                        manual
                        showPageJump={false}
                        page={pageNr}
                        pages={pages}
                        noDataText={
                            <div className="view-shipplan__no-results">
                                <h3>No results found</h3>
                                <p>Make sure you have:</p>
                                <ul>
                                    <li>configured the right filters on the left</li>
                                    <li>
                                        added all the required files to create a ship plan{' '}
                                        <Link to="create-shipplan">here</Link>
                                    </li>
                                    <li>
                                        taken a look at the <Link to="documentation">documentation</Link>
                                    </li>
                                </ul>
                            </div>
                        }
                        resizable={false}
                        loading={pending}
                        defaultPageSize={SHIPPLAN_PAGE_SIZE}
                        showPagination={true}
                        showPaginationTop={false}
                        paginationBottom={true}
                        onPageChange={this.handlePageChange}
                        className="-striped -highlight"
                        defaultSorted={sorting}
                        onSortedChange={this.handleSorting}
                        showPageSizeOptions={false}
                        style={{
                            height: 'calc(100vh - 60px)'
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default ViewShipplan;
