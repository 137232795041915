import React, { Component } from 'react';
import PropTypes, { shape, func, string, bool } from 'prop-types';
import DatePicker from '@nike/nr-vamp-xl-date-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './SeasonForm.scss';
import { formatStringToDateAndAdd, formatStringToDate, formatDateToString } from '../NewSeason/season-helper';
import ConfirmButton from '../ConfirmButton/ConfirmButton';

const styleExtension = { icon: { top: 10, right: 8 } };

export default class SeasonForm extends Component {
    static propTypes = {
        onSubmit: func.isRequired,
        defaultValues: shape({
            name: string,
            startDate: PropTypes.date,
            flow2StartDate: PropTypes.date,
            flow3StartDate: PropTypes.date,
            endDate: PropTypes.date
        }),
        buttonLabel: string,
        confirmButtonLabel: string,
        error: string,
        loading: bool,
        success: bool,
        disabled: bool
    };

    // eslint-disable-next-line react/destructuring-assignment
    state = this.props.defaultValues || {
        name: '',
        startDate: null,
        flow2StartDate: null,
        flow3StartDate: null,
        endDate: null
    };

    handleInputChange = event => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value
        });
    };

    handleDateChange = (name, date) => {
        this.setState({
            [name]: formatDateToString(date)
        });
    };

    handleSubmit = e => {
        if (e) e.preventDefault();
        const { onSubmit } = this.props;
        onSubmit(this.state);
    };

    render() {
        const { error, loading, success, buttonLabel, confirmButtonLabel, disabled } = this.props;
        const { name, startDate, endDate, flow2StartDate, flow3StartDate } = this.state;

        return (
            <div className="SeasonForm">
                <form className="SeasonForm__form" onSubmit={this.handleSubmit}>
                    <input
                        type="text"
                        value={name}
                        name="name"
                        id="name"
                        placeholder="e.g. HO21"
                        pattern="[A-Z]{2}[0-9]{2}"
                        data-cy="seasonform-name-input"
                        onChange={this.handleInputChange}
                    />
                    <label htmlFor="name">Season name</label>

                    <div className="input">
                        <DatePicker
                            hintText="YYYY-MM-DD"
                            InputProps={{ id: 'startDate', 'data-cy': 'seasonform-seasonstart-input' }}
                            styleExtension={styleExtension}
                            onChange={date => this.handleDateChange('startDate', date)}
                            value={formatStringToDate(startDate)}
                            formatDate={formatDateToString}
                        />
                    </div>
                    <label htmlFor="startDate">Season start date</label>

                    <div className="input">
                        <DatePicker
                            hintText="YYYY-MM-DD"
                            InputProps={{ id: 'startFlow2', 'data-cy': 'seasonform-flow2start-input' }}
                            styleExtension={styleExtension}
                            minDate={formatStringToDateAndAdd(startDate, { days: 1 })}
                            onChange={date => this.handleDateChange('flow2StartDate', date)}
                            value={formatStringToDate(flow2StartDate)}
                            formatDate={formatDateToString}
                        />
                    </div>
                    <label htmlFor="startFlow2">Start flow 2</label>

                    <div className="input">
                        <DatePicker
                            hintText="YYYY-MM-DD"
                            InputProps={{ id: 'startFlow3', 'data-cy': 'seasonform-flow3start-input' }}
                            styleExtension={styleExtension}
                            minDate={formatStringToDateAndAdd(flow2StartDate, { days: 1 })}
                            onChange={date => this.handleDateChange('flow3StartDate', date)}
                            value={formatStringToDate(flow3StartDate)}
                            formatDate={formatDateToString}
                        />
                    </div>
                    <label htmlFor="startFlow3">Start flow 3</label>

                    <div className="input">
                        <DatePicker
                            hintText="YYYY-MM-DD"
                            InputProps={{ id: 'endDate', 'data-cy': 'seasonform-seasonend-input' }}
                            styleExtension={styleExtension}
                            minDate={formatStringToDateAndAdd(flow3StartDate, { days: 1 })}
                            onChange={date => this.handleDateChange('endDate', date)}
                            value={formatStringToDate(endDate)}
                            formatDate={formatDateToString}
                        />
                    </div>
                    <label htmlFor="endDate">Season end date</label>

                    <div className="input">
                        <ConfirmButton
                            onClick={this.handleSubmit}
                            loading={loading}
                            label={buttonLabel}
                            confirmLabel={confirmButtonLabel}
                            disabled={disabled}
                            data-cy="seasonform-submit"
                        />
                    </div>

                    {error && (
                        <div className="SeasonForm__error output">
                            <FontAwesomeIcon icon={faExclamationTriangle} /> {error}
                        </div>
                    )}
                    {success && (
                        <div className="SeasonForm__success output">
                            <FontAwesomeIcon icon={faCheckCircle} /> success
                        </div>
                    )}
                </form>
            </div>
        );
    }
}
