export const autoCompleteFilter = (haystack, needle) => {
    if (!needle) return haystack;

    const regex = new RegExp(
        `(.*${needle
            .trim()
            .toLowerCase()
            .replace(/ /g, '.*) (.*')}.*)`,
        'i'
    );

    return haystack.filter(sentence => regex.test(sentence));
};
