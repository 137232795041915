import React, { PureComponent, Fragment } from 'react';
import { func, oneOf } from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHashtag,
    faDollarSign,
    faStore,
    faTruckLoading,
    faGlobeEurope,
    faMapMarkedAlt
} from '@fortawesome/free-solid-svg-icons';
import Toggle from '../Toggle/Toggle';
import './UnitToggle.scss';

const unitTypes = [
    {
        icon: '1-9',
        title: 'Quantity',
        value: 'quantity'
    },
    {
        icon: (
            <Fragment>
                <FontAwesomeIcon icon={faDollarSign} />
                {` `}
            </Fragment>
        ),
        title: 'Price',
        value: 'price'
    }
];

const priceRegions = [
    {
        icon: (
            <span>
                <FontAwesomeIcon icon={faGlobeEurope} />
            </span>
        ),
        title: 'The Netherlands',
        value: 'netherlands'
    },
    {
        icon: (
            <span>
                <FontAwesomeIcon icon={faMapMarkedAlt} />
            </span>
        ),
        title: 'Local',
        value: 'local'
    }
];

const priceModels = [
    {
        icon: (
            <span>
                <FontAwesomeIcon icon={faStore} />
            </span>
        ),
        title: 'Retail price',
        value: 'retail'
    },
    {
        icon: (
            <span>
                <FontAwesomeIcon icon={faTruckLoading} />
            </span>
        ),
        title: 'Wholesale price',
        value: 'wholesale'
    }
];

class UnitToggle extends PureComponent {
    state = {};

    static getDerivedStateFromProps(props) {
        if (props.fixedRegion && props.priceRegion !== props.fixedRegion) {
            props.setPriceRegion(props.fixedRegion);
        }
        return {};
    }

    handleUnitTypeChange = async value => {
        const { onToggle, setUnitType } = this.props;
        await setUnitType(value);
        onToggle();
    };

    handlePriceRegionChange = async value => {
        const { onToggle, setPriceRegion } = this.props;
        await setPriceRegion(value);
        onToggle();
    };

    handlePriceModelChange = async value => {
        const { onToggle, setPriceModel } = this.props;
        await setPriceModel(value);
        onToggle();
    };

    render() {
        const { unitType, priceRegion, priceModel, orientation, fixedRegion } = this.props;
        return (
            <div className={cn('UnitToggle', `UnitToggle--orientation-${orientation}`)}>
                {orientation === 'horizontal' && (
                    <h3>
                        <FontAwesomeIcon icon={faHashtag} />
                        &nbsp; Unit type
                    </h3>
                )}
                <section>
                    <fieldset className="UnitToggle__units">
                        <legend>Units</legend>
                        <Toggle
                            active={unitType}
                            items={unitTypes}
                            onChange={this.handleUnitTypeChange}
                            orientation={orientation}
                        />
                    </fieldset>
                    <fieldset disabled={unitType === 'quantity' || fixedRegion !== undefined} className="UnitToggle__region">
                        <legend>Region</legend>
                        <Toggle
                            active={priceRegion}
                            items={priceRegions}
                            onChange={this.handlePriceRegionChange}
                            disabled={unitType === 'quantity' || fixedRegion !== undefined}
                            orientation={orientation}
                        />
                    </fieldset>
                    <fieldset disabled={unitType === 'quantity'} className="UnitToggle__model">
                        <legend>Model</legend>
                        <Toggle
                            active={priceModel}
                            items={priceModels}
                            onChange={this.handlePriceModelChange}
                            disabled={unitType === 'quantity'}
                            orientation={orientation}
                        />
                    </fieldset>
                </section>
            </div>
        );
    }
}

UnitToggle.propTypes = {
    setUnitType: func.isRequired,
    setPriceModel: func.isRequired,
    setPriceRegion: func.isRequired,
    unitType: oneOf(['quantity', 'price']).isRequired,
    priceRegion: oneOf(['local', 'netherlands']),
    fixedRegion: oneOf(['local', 'netherlands']),
    priceModel: oneOf(['retail', 'wholesale']),
    orientation: oneOf(['horizontal', 'vertical']),
    onToggle: func
};

UnitToggle.defaultProps = {
    orientation: 'horizontal',
    onToggle: () => {}
};

export default UnitToggle;
