import React from 'react';
import { number, string, any } from 'prop-types';
import './UnitCell.scss';

const getDescription = (unitType, priceRegion, priceModel, country) => {
    if (unitType === 'quantity') {
        return 'Quantity';
    }

    if (priceRegion === 'local' && !country) {
        return `local ${priceModel} price in USD`;
    }

    return `USD ${priceModel} price in ${priceRegion === 'local' ? country : 'the Netherlands'}`;
};

const UnitCell = ({ value, unitType, priceRegion, priceModel, country }) =>
    value !== undefined ? (
        <span className="UnitCell" title={getDescription(unitType, priceRegion, priceModel, country)}>
            {new Intl.NumberFormat('en-US', unitType === 'price' ? { style: 'currency', currency: 'USD' } : {}).format(
                value
            )}
        </span>
    ) : null;

UnitCell.propTypes = { value: number, unitType: string, priceRegion: string, priceModel: string, country: any };

export default UnitCell;
