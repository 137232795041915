import React from 'react';
import { func, string, bool } from 'prop-types';
import cn from 'classnames';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import './FileDrop.scss';

const FileDrop = ({ acceptedMimeTypes, onDrop, title, multiple, onToggle }) => (
    <div className="file-drop">
        {onToggle && (
            <button type="button" className="file-drop__reupload" onClick={onToggle}>
                <FontAwesomeIcon icon={faSyncAlt} />
            </button>
        )}
        <Dropzone
            onDrop={onDrop}
            accept={acceptedMimeTypes}
            className="dropzone"
            activeClassName="active"
            rejectClassName="reject"
            multiple={multiple}
        >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <div
                    {...getRootProps()}
                    className={cn('dropzone', { 'dropzone--isActive': isDragActive, 'dropzone--isRejected': isDragReject })}
                >
                    <input {...getInputProps()} />
                    {title && <span className="dropzone__title">+ Add {title}</span>}
                    {isDragReject ? (
                        <span>File(s) not valid</span>
                    ) : (
                        <span>
                            <span className="dropzone__browse">Choose a file</span> or drag it here
                        </span>
                    )}
                </div>
            )}
        </Dropzone>
    </div>
);

FileDrop.propTypes = {
    acceptedMimeTypes: string,
    title: string,
    onDrop: func,
    multiple: bool,
    onToggle: func
};

FileDrop.defaultProps = {
    multiple: true
};

export default FileDrop;
