import React, { Component } from 'react';
import cn from 'classnames';
import { number, func, string, bool, oneOfType } from 'prop-types';
import './Override.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import OverrideLock from './OverrideLock';

class OverrideMaterialStore extends Component {
    static propTypes = {
        storeNumber: oneOfType([string, number]).isRequired,
        material: string.isRequired,
        loadIn: number.isRequired,
        replen: number.isRequired,
        overrideMaterialStore: func.isRequired,
        overrideDelete: func.isRequired,
        maximum: number.isRequired,
        flowNumber: number.isRequired,
        hasOverride: bool.isRequired,
        disabled: bool,
        loading: bool
    };

    state = {
        error: undefined,
        editing: false,
        changedLoadIn: false,
        changedReplen: false,
        changed: false
    };

    static getDerivedStateFromProps(props, state) {
        if (!state.editing && !props.loading) {
            return {
                newLoadIn: props.loadIn,
                newReplen: props.replen
            };
        }

        return null;
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    handleLoadInChange = e => {
        const { newReplen } = this.state;
        const { maximum } = this.props;
        const value = parseInt(e.target.value, 10);

        if (Number.isNaN(value)) {
            this.setState({ newLoadIn: '', changed: true, changedLoadIn: false });
        } else if (value <= maximum - newReplen) {
            this.setState({ newLoadIn: value, changed: true, changedLoadIn: true });
        }
    };

    handleReplenChange = e => {
        const { newLoadIn } = this.state;
        const { maximum } = this.props;
        const value = parseInt(e.target.value, 10);

        if (Number.isNaN(value)) {
            this.setState({ newReplen: '', changed: true, changedReplen: false });
        } else if (value <= maximum - newLoadIn) {
            this.setState({ newReplen: value, changed: true, changedReplen: true });
        }
    };

    handleFocus = () => {
        this.setState({ editing: true });
    };

    handleBlur = async e => {
        if (e && e.persist) {
            e.persist();
        }

        const { storeNumber, flowNumber, loadIn, replen } = this.props;
        const { newLoadIn, newReplen, changedLoadIn, changedReplen } = this.state;

        if (!Number.isInteger(newLoadIn)) {
            await this.setState({ newLoadIn: loadIn });
        }

        if (!Number.isInteger(newReplen)) {
            await this.setState({ newReplen: replen });
        }

        if (!changedLoadIn && !changedReplen) {
            this.setState({ changed: false });
            return;
        }

        if (
            e.relatedTarget &&
            (e.relatedTarget.id === `loadIn-${storeNumber}-${flowNumber}` ||
                e.relatedTarget.id === `replen-${storeNumber}-${flowNumber}`)
        ) {
            return;
        }

        if (!this.skipSubmitOnBlur) {
            this.submit();
        }
        this.setState({ editing: false });
        this.skipSubmitOnBlur = false;
    };

    handleInputKeyUp = e => {
        // Blur instead of running form submit when pressing enter.
        // The onBlur handler will submit as well
        if (e.which === 13) {
            e.preventDefault();
            e.target.blur();
        }

        // Escape key
        if (e.which === 27) {
            this.resetInput();
        }
    };

    submit = async e => {
        const { changed } = this.state;

        if (e) {
            e.preventDefault();
        }
        if (!changed) {
            return;
        }

        this.skipSubmitOnBlur = true;
        document.activeElement.blur();

        await this.override();
    };

    override = async () => {
        const { overrideMaterialStore, flowNumber, material, storeNumber } = this.props;
        const { newLoadIn, newReplen } = this.state;

        try {
            await overrideMaterialStore({
                Material: material,
                StoreNumber: storeNumber,
                flowNumber,
                loadInQuantity: newLoadIn,
                replenQuantity: newReplen
            });
        } catch (err) {
            this.setState({ error: err.message });
            this.delayedClearError();
        } finally {
            this.setState({ editing: false, changed: false });
        }
    };

    deleteOverride = async () => {
        const { overrideDelete, flowNumber, material, storeNumber } = this.props;

        try {
            await overrideDelete({
                Material: material,
                StoreNumber: storeNumber,
                flowNumber
            });
        } catch (err) {
            this.setState({ error: err.message });
            this.delayedClearError();
        } finally {
            this.setState({ editing: false, changed: false });
        }
    };

    resetInput = () => {
        const { loadIn, replen } = this.props;
        this.skipSubmitOnBlur = true;
        document.activeElement.blur();
        this.setState({ newLoadIn: loadIn, newReplen: replen, editing: false, changed: false });
    };

    handleErrorClick = () => {
        this.setState({ error: undefined });
    };

    delayedClearError = () => {
        this.timeout = setTimeout(() => {
            this.setState({ error: undefined });
        }, 2500);
    };

    render() {
        const { maximum, disabled, storeNumber, hasOverride, flowNumber, loading } = this.props;
        const { editing, newLoadIn, newReplen, error, changed } = this.state;
        const loadInMaximum = maximum - newReplen;
        const replenMaximum = maximum - newLoadIn;

        return (
            <form onSubmit={this.submit}>
                <div
                    className={cn('Override', 'Override--materialstore', {
                        'Override--editing': editing,
                        'Override--disabled': disabled
                    })}
                >
                    <input
                        type="number"
                        name="loadIn"
                        id={`loadIn-${storeNumber}-${flowNumber}`}
                        value={newLoadIn}
                        onChange={this.handleLoadInChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onKeyUp={this.handleInputKeyUp}
                        disabled={disabled}
                        max={loadInMaximum}
                        min={0}
                    />
                    <input
                        type="number"
                        name="replen"
                        id={`replen-${storeNumber}-${flowNumber}`}
                        value={newReplen}
                        onChange={this.handleReplenChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onKeyUp={this.handleInputKeyUp}
                        disabled={disabled}
                        max={replenMaximum}
                        min={0}
                    />
                    <OverrideLock
                        loading={loading}
                        hasOverride={hasOverride}
                        changed={changed}
                        onClick={changed || !hasOverride ? this.override : this.deleteOverride}
                    />

                    {error && (
                        <span
                            className="Override__error Override__overlay"
                            tabIndex={0}
                            role="button"
                            onClick={this.handleErrorClick}
                        >
                            <FontAwesomeIcon icon={faExclamationTriangle} /> {error}
                        </span>
                    )}
                </div>
            </form>
        );
    }
}

export default OverrideMaterialStore;
