import { connect } from 'react-redux';
import { getSeason } from '../../actions/season';
import { setInvalidOverrides } from '../../actions/override';
import FileUploadStep from './FileUploadStep';

const mapDispatchToProps = {
    getSeason,
    setInvalidOverrides
};

const mapStateToProps = ({
    season: {
        season: { buyplan, orderbook, aareplen, storeranks, carryovers, actions }
    }
}) => ({ buyplan, orderbook, aareplen, storeranks, carryovers, actions: actions || [] });

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadStep);
