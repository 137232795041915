import qs from 'qs';
import request from '../api/api';

const getShipplan = (pageSize, pageNr, activeFilters, sorted, groupBy, unitType, priceModel, priceRegion, signal) => {
    const queryParams = {
        pagesize: pageSize,
        pagenr: pageNr,
        filter: activeFilters,
        sorted,
        groupBy,
        unitType,
        priceModel,
        priceRegion
    };

    return request({
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/seasons/current/shipplan?${qs.stringify(queryParams)}`,
        signal
    });
};
const getMaterial = (material, sorting, unitType, priceModel, priceRegion) => {
    const queryParams = {
        sorting,
        unitType,
        priceModel,
        priceRegion
    };
    return request({
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/seasons/current/shipplan/${material}?${qs.stringify(queryParams)}`
    });
};

const getShipplanMetaData = () =>
    request({
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/seasons/current/shipplan/meta`
    });

export default { getShipplan, getShipplanMetaData, getMaterial };
