import React, { Component, Fragment } from 'react';
import { string, arrayOf, func } from 'prop-types';
import moment from 'moment';
import DateRangePicker from '@nike/nr-vamp-xl-date-range-picker';
import CheckBox from '../CheckBox/CheckBox';
import { autoCompleteFilter } from '../../helpers/autocomplete-filter';
import Highlighter from './Highlighter';
import FilterInput from './FilterInput';
import './Filter.scss';

export const formatDateToString = date => moment(date).format('YYYY-MM-DD');
export const VISIBLE_FILTER_THRESHOLD = 6;
export const AUTOCOMPLETE_THRESHOLD = 20;

export const toggleFilter = (filters = [], value) => {
    const index = filters.indexOf(value);
    if (index === -1) {
        return [...filters, value];
    }
    return [...filters.slice(0, index), ...filters.slice(index + 1)];
};

export const sortActiveFilters = (activeFilters = []) => (a, b) => {
    const aMatch = activeFilters.includes(a);
    const bMatch = activeFilters.includes(b);
    if ((!aMatch && !bMatch) || (aMatch && bMatch)) {
        return a.localeCompare(b, undefined, { numeric: true });
    }

    return aMatch ? -1 : 1;
};

class Filter extends Component {
    constructor(props) {
        super(props);
        const { activeFilter } = this.props;
        this.state = {
            expanded: false,
            hidden: !activeFilter.length,
            filterText: ''
        };

        this.handleDateChange = this.handleDateChange.bind(this);
        this.filterData = this.filterData.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { activeFilter } = this.props;
        if (activeFilter !== prevProps.activeFilter) {
            if (activeFilter.length === 0) {
                this.setState({
                    hidden: true,
                    filterText: ''
                });
            }
        }
    }

    handleExpand = () => {
        this.setState({ expanded: true });
    };

    toggleHidden = () => {
        const { hidden } = this.state;
        this.setState({ hidden: !hidden });
    };

    handleDateChange({ start, end }) {
        const endDate = end ? moment(end).format('YYYY-MM-DD') : null;
        const startDate = start ? moment(start).format('YYYY-MM-DD') : null;
        const { setFilter } = this.props;
        setFilter([startDate, endDate]);
    }

    handleChange(value) {
        const { setFilter, activeFilter } = this.props;
        setFilter(toggleFilter(activeFilter, value));
    }

    selectFilterType() {
        const { filterType } = this.props;
        switch (filterType) {
            case 'multiselect':
            case 'boolean':
                return this.renderCheckBox();
            case 'dateRange':
                return this.renderDateRangePicker();
            default:
                return null;
        }
    }

    filterData(e) {
        this.setState({
            filterText: e.target.value,
            expanded: false
        });
    }

    renderCheckBox() {
        const { field, filterData, activeFilter } = this.props;
        const { filterText } = this.state;
        const { expanded, hidden } = this.state;

        const showAutoComplete = filterData.length > AUTOCOMPLETE_THRESHOLD;
        const autoCompletedFilterData = autoCompleteFilter(filterData, filterText);
        const hiddenFilterCount = autoCompletedFilterData.length - VISIBLE_FILTER_THRESHOLD;
        const showHiddenFilterIndicator = hiddenFilterCount > 0 && !expanded;
        let displayedFilterCount = expanded ? autoCompletedFilterData.length : VISIBLE_FILTER_THRESHOLD;
        if (activeFilter && activeFilter.length > displayedFilterCount) {
            displayedFilterCount = activeFilter.length;
        }

        if (hidden || !filterData) {
            return null;
        }

        return (
            <Fragment>
                <div className="selects">
                    {showAutoComplete && (
                        <FilterInput type="text" onChange={this.filterData} value={filterText} field={field} />
                    )}
                    <ul>
                        {autoCompletedFilterData
                            .sort(sortActiveFilters(activeFilter))
                            .slice(0, displayedFilterCount)
                            .map(filteritem => {
                                const key = `${field}_${filteritem.replace(/\s/g, '')}`;
                                return (
                                    <li key={key}>
                                        <CheckBox
                                            label={
                                                <Highlighter highlight={filterText.split(' ')} text={filteritem || 'none'} />
                                            }
                                            id={key}
                                            isChecked={activeFilter && activeFilter.indexOf(filteritem) > -1}
                                            onChange={() => this.handleChange(filteritem)}
                                        />
                                    </li>
                                );
                            })}
                    </ul>
                    {showHiddenFilterIndicator &&
                        (hiddenFilterCount > AUTOCOMPLETE_THRESHOLD ? (
                            <span className="more-text">(+ {hiddenFilterCount} hidden filters)</span>
                        ) : (
                            <button type="button" onClick={this.handleExpand} className="more-button zero">
                                + more ({hiddenFilterCount})
                            </button>
                        ))}
                </div>
            </Fragment>
        );
    }

    renderDateRangePicker() {
        const { hidden } = this.state;
        const { activeFilter } = this.props;

        const startDate = activeFilter[0];
        const endDate = activeFilter[1];
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;

        return (
            !hidden && (
                <DateRangePicker
                    hintText="YYYY-MM-DD"
                    onChange={this.handleDateChange}
                    formatDate={formatDateToString}
                    valueStart={start}
                    valueEnd={end}
                    showDifference={false}
                    controlled
                />
            )
        );
    }

    render() {
        const { label } = this.props;
        const { hidden } = this.state;

        return (
            <div className="filter">
                <button type="button" className="heading" tabIndex={0} onClick={this.toggleHidden}>
                    <div className="platform">{label}</div>
                    <span className={`glyph glyph--${hidden ? 'plus' : 'minus'} collapsed show-button`} />
                </button>
                {this.selectFilterType()}
                <span className="keyline" />
            </div>
        );
    }
}

Filter.propTypes = {
    setFilter: func.isRequired,
    field: string,
    label: string,
    filterData: arrayOf(string),
    filterType: string,
    activeFilter: arrayOf(string)
};

Filter.defaultProps = {
    filterData: [],
    activeFilter: []
};

export default Filter;
