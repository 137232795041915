import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { shape, func, string, bool, arrayOf } from 'prop-types';
import SeasonForm from '../SeasonForm/SeasonForm';
import { formatDateToString } from '../NewSeason/season-helper';

class EditSeason extends Component {
    static propTypes = {
        editSeason: func.isRequired,
        history: shape({ push: func }).isRequired,
        currentSeason: shape({}).isRequired,
        actions: arrayOf(string).isRequired,
        error: string,
        loading: bool,
        success: bool
    };

    handleSubmit = async data => {
        const { editSeason, history } = this.props;
        const { name, startDate, flow2StartDate, flow3StartDate, endDate } = data;
        try {
            await editSeason({
                name,
                startDate: formatDateToString(startDate),
                flow2StartDate: formatDateToString(flow2StartDate),
                flow3StartDate: formatDateToString(flow3StartDate),
                endDate: formatDateToString(endDate)
            });
            history.push('/create-shipplan');
        } catch (err) {
            // Feedback is on its way...
        }
    };

    render() {
        const { currentSeason, error, loading, success, actions } = this.props;

        return (
            <Container>
                <Row>
                    <Col xs={{ span: 8, offset: 2 }}>
                        <h1>EDIT SEASON</h1>
                        <SeasonForm
                            onSubmit={this.handleSubmit}
                            defaultValues={currentSeason}
                            buttonLabel="Edit season"
                            confirmButtonLabel="Really edit season?"
                            error={error}
                            loading={loading}
                            success={success}
                            disabled={!actions.includes('editSeason')}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default EditSeason;
