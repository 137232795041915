import { connect } from 'react-redux';
import { setUnitType, setPriceModel, setPriceRegion } from '../../actions/unitToggle';
import UnitToggle from './UnitToggle';

const mapActionCreators = {
    setUnitType,
    setPriceModel,
    setPriceRegion
};

const mapStateToProps = ({ unitToggle }) => ({
    unitType: unitToggle.unitType,
    priceModel: unitToggle.priceModel,
    priceRegion: unitToggle.priceRegion
});

const UnitToggleContainer = connect(mapStateToProps, mapActionCreators)(UnitToggle);
UnitToggleContainer.displayName = 'UnitToggleContainer';
export default UnitToggleContainer;
