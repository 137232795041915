import { connect } from 'react-redux';
import { uploadPartnerProposalSuccess } from '../../actions/partnerProposals';
import { getUploadError } from '../../reducers/partnerProposals';
import InvalidPartnerProposalModal from './InvalidPartnerProposalModal';

const mapStateToProps = state => {
    const error = getUploadError(state);
    return {
        uploadErrorMessage: error.message
    };
};
const mapDispatchToProps = {
    onClose: uploadPartnerProposalSuccess
};

export default connect(mapStateToProps, mapDispatchToProps)(InvalidPartnerProposalModal);
