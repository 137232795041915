import React from 'react';
import { oneOfType, arrayOf, node } from 'prop-types';
import Header from '../Header/Header';
import './Layout.scss';

const Layout = ({ children }) => (
    <div className="layout">
        <Header />
        <div className="content">{children}</div>
    </div>
);

Layout.propTypes = {
    children: oneOfType([arrayOf(node), node]).isRequired
};

export default Layout;
