import React, { Fragment } from 'react';
import { arrayOf, string } from 'prop-types';
import './Highlight.scss';

export const getHighlightedChunks = (highlights, text) => {
    let last;

    return highlights
        .flatMap(highlightText => {
            const indexes = [];
            if (!highlightText) return indexes;

            const regex = new RegExp(highlightText, 'gi');
            const { length } = highlightText;
            let match;

            // eslint-disable-next-line no-cond-assign
            while ((match = regex.exec(text))) {
                indexes.push({ start: match.index, end: match.index + length });
            }

            return indexes;
        })
        .map(highlight => {
            // Merge overlapping ranges into one long range:
            if (!last || highlight.start > last.end) {
                last = highlight;
                return highlight;
            }

            if (highlight.end > last.end) {
                last.end = highlight.end;
            }

            return null;
        })
        .filter(Boolean);
};

export const getHighlightedText = (chunks, text) => {
    if (!chunks.length) return text;

    const highlighted = chunks.flatMap((chunk, index) => {
        const prefix = index ? text.substring(chunks[index - 1].end, chunk.start) : text.substring(0, chunk.start);
        return [
            prefix,
            <span className="highlight" key={chunk.start}>
                {text.substring(chunk.start, chunk.end)}
            </span>
        ];
    });

    return [...highlighted, text.substring(chunks[chunks.length - 1].end, text.length)];
};

const Highlighter = ({ highlight, text }) => {
    const chunks = getHighlightedChunks(highlight, text);
    return <Fragment>{getHighlightedText(chunks, text)}</Fragment>;
};

Highlighter.propTypes = {
    highlight: arrayOf(string),
    text: string.isRequired
};

Highlighter.defaultProps = {
    highlight: []
};

export default Highlighter;
