import React from 'react';
import ReactDOM from 'react-dom';
import { NikeThemeProvider } from '@nike/nr-sole-xl-theme-provider';
import { Provider } from 'react-redux';
import Modal from 'react-modal';
import Routes from './routes/routes';
import createStore from './store/createStore';

import './components/core.scss';
import './components/Header/Header.scss';

const store = createStore();

const MOUNT_NODE = document.getElementById('root');

const render = () => {
    ReactDOM.render(
        <Provider store={store}>
            <NikeThemeProvider>
                <Routes store={store} />
            </NikeThemeProvider>
        </Provider>,
        MOUNT_NODE
    );
};

Modal.setAppElement(MOUNT_NODE);

render();
