import React from 'react';
import { bool, func } from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import Loader from '../Loader/Loader';
import './OverrideLock.scss';

const OverrideLock = ({ hasOverride, loading, changed, onClick }) => {
    if (loading) {
        return (
            <span className="OverrideLock">
                <Loader width={12} />
            </span>
        );
    }
    return (
        <span
            className={cn('OverrideLock', {
                'OverrideLock--has-override': hasOverride,
                'OverrideLock--submit': changed || !hasOverride,
                'OverrideLock--inactive': !changed && !hasOverride,
                'OverrideLock--delete': !changed && hasOverride
            })}
            onClick={onClick}
            tabIndex={0}
            role="button"
        >
            <FontAwesomeIcon icon={faLock} className="OverrideLock-icon-lock" />
            <FontAwesomeIcon icon={faUnlock} className="OverrideLock-icon-unlock" />
        </span>
    );
};

OverrideLock.propTypes = {
    hasOverride: bool.isRequired,
    loading: bool,
    changed: bool,
    onClick: func.isRequired
};

export default OverrideLock;
