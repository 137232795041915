import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import _ from 'lodash';
import persistState, { mergePersistedState } from 'redux-localstorage';
import adapter from 'redux-localstorage/lib/adapters/localStorage';
import filter from 'redux-localstorage-filter';
import makeRootReducer from './reducers';

export default () => {
    const middleware = [thunkMiddleware];

    const mergedState = compose(mergePersistedState((initState, persistedState) => _.merge({}, initState, persistedState)))(
        makeRootReducer()
    );

    const storage = compose(
        filter(['shipplan.activeFilters', 'shipplan.groupBy', 'shipplan.sidebarOpen', 'unitToggle']) // persist store
    )(adapter(window.localStorage));
    const enhancers = [persistState(storage)];

    /* eslint-disable no-underscore-dangle */
    const composeEnhancers =
        typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
              })
            : compose;
    /* eslint-enable */

    const store = createStore(mergedState, {}, composeEnhancers(applyMiddleware(...middleware), ...enhancers));

    return store;
};
