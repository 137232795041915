import React, { Component } from 'react';
import cn from 'classnames';
import { number, func, string, bool } from 'prop-types';
import './Override.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import OverrideLock from './OverrideLock';

class OverrideMaterial extends Component {
    static propTypes = {
        material: string.isRequired,
        coverage: number.isRequired,
        overrideMaterial: func.isRequired,
        overrideDelete: func.isRequired,
        maximum: number.isRequired,
        flowNumber: number.isRequired,
        hasOverride: bool.isRequired,
        decisionStatement: string,
        softMin: number,
        disabled: bool,
        loading: bool
    };

    state = {
        error: undefined,
        editing: false,
        changed: false
    };

    static getDerivedStateFromProps(props, state) {
        if (!state.editing && !props.loading) {
            return {
                newCoverage: props.coverage
            };
        }

        return null;
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    handleChange = e => {
        const { maximum } = this.props;
        const newCoverage = parseInt(e.target.value, 10);

        if (Number.isNaN(newCoverage)) {
            this.setState({ newCoverage: '', changed: true });
        } else if (newCoverage <= maximum) {
            this.setState({ newCoverage, changed: true });
        }
    };

    handleFocus = () => {
        this.setState({ editing: true });
    };

    handleBlur = async () => {
        const { coverage } = this.props;
        const { newCoverage } = this.state;
        // Go back to the old value and leave unchanged when there is no input
        if (!Number.isInteger(newCoverage)) {
            await this.setState({ newCoverage: coverage, changed: false });
        } else if (!this.skipSubmitOnBlur) {
            this.submit();
        }

        this.setState({ editing: false });
        this.skipSubmitOnBlur = false;
    };

    handleInputKeyDown = e => {
        // Blur instead of running form submit when pressing enter.
        // The onBlur handler will submit as well
        if (e.which === 13) {
            e.preventDefault();
            e.target.blur();
        }

        // Escape key
        if (e.which === 27) {
            this.resetInput();
        }
    };

    submit = async e => {
        const { changed } = this.state;

        if (e) {
            e.preventDefault();
        }
        if (!changed) {
            return;
        }

        this.skipSubmitOnBlur = true;
        document.activeElement.blur();

        await this.override();
    };

    override = async () => {
        const { overrideMaterial, flowNumber, material } = this.props;
        const { newCoverage } = this.state;

        try {
            await overrideMaterial({
                Material: material,
                flowNumber,
                quantity: newCoverage
            });
        } catch (err) {
            // Wait for state update until starting delayedClearError timer for consistent timing
            await this.setState({ error: err.message });
            this.delayedClearError();
        } finally {
            this.setState({ editing: false, changed: false });
        }
    };

    deleteOverride = async () => {
        const { overrideDelete, flowNumber, material } = this.props;

        try {
            await overrideDelete({
                Material: material,
                flowNumber
            });
        } catch (err) {
            // Wait for state update until starting delayedClearError timer for consistent timing
            await this.setState({ error: err.message });
            this.delayedClearError();
        } finally {
            this.setState({ editing: false, changed: false });
        }
    };

    resetInput = () => {
        const { coverage } = this.props;
        this.skipSubmitOnBlur = true;
        document.activeElement.blur();
        this.setState({ newCoverage: coverage, editing: false, changed: false });
    };

    handleErrorClick = () => {
        this.setState({ error: undefined });
    };

    delayedClearError = () => {
        this.timeout = setTimeout(() => {
            this.setState({ error: undefined });
        }, 2500);
    };

    render() {
        const { maximum, disabled, softMin, hasOverride, loading, decisionStatement } = this.props;
        const { editing, error, changed, newCoverage } = this.state;

        // Needed for Material level
        let belowSoftMinimum = false;
        if (editing && !error && Number.isInteger(newCoverage) && newCoverage < softMin) {
            belowSoftMinimum = true;
        }

        return (
            <form onSubmit={this.submit}>
                <div
                    className={cn('Override', 'Override--material', {
                        'Override--editing': editing,
                        'Override--disabled': disabled
                    })}
                    title={decisionStatement}
                >
                    <input
                        type="number"
                        value={newCoverage}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onKeyDown={this.handleInputKeyDown}
                        disabled={disabled}
                        max={maximum}
                        min={0}
                    />
                    <OverrideLock
                        loading={loading}
                        hasOverride={hasOverride}
                        changed={changed}
                        onClick={changed || !hasOverride ? this.override : this.deleteOverride}
                    />

                    {error && (
                        <span
                            className="Override__error Override__overlay"
                            tabIndex={0}
                            role="button"
                            onClick={this.handleErrorClick}
                        >
                            <FontAwesomeIcon icon={faExclamationTriangle} /> {error}
                        </span>
                    )}
                    {belowSoftMinimum && (
                        <span className="Override__warning Override__overlay">
                            <FontAwesomeIcon icon={faExclamationTriangle} /> This will delete all overrides for this material
                        </span>
                    )}
                </div>
            </form>
        );
    }
}

export default OverrideMaterial;
