import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import foldableTable from 'react-table/lib/hoc/foldableTable';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { func, string, shape, array, bool, arrayOf } from 'prop-types';
import 'react-table/react-table.css';
import getColumnsByGroup, { getTotalColumnsWidth } from '../ViewShipplan/columns';
import { groupBy as groupByGroups } from '../GroupBy/GroupBy';
import './ViewMaterial.scss';
import UnitToggleContainer from '../UnitToggle/UnitToggleContainer';

const Table = foldableTable(ReactTable);
class ViewMaterial extends Component {
    static propTypes = {
        getMaterial: func.isRequired,
        materialCode: string,
        // eslint-disable-next-line react/no-unused-prop-types
        unitType: string,
        actions: arrayOf(string),
        loading: bool,
        setSorting: func,
        sorting: arrayOf(shape({ id: string, desc: bool })),
        material: shape({
            stores: array,
            AAReplen: string
        })
    };

    state = {};

    static getDerivedStateFromProps(props, state) {
        // We're intentionally not reacting to intermittent changes to whether or not the override action is allowed
        // This would make it very jumpy on updating overrides as the lock icons will dissappear for a second and re-appear,
        // making everything very annoying. Instead, we'll send a disabled prop to the MaterialOverrides containers when
        // overrides are not possible intermittently
        const { actions, unitType, material = {} } = props;
        const { AAReplen } = material;
        if (unitType !== state.unitType || AAReplen !== state.AAReplen) {
            const materialHeader = (
                <span>
                    <Link to="/view-shipplan">
                        <FontAwesomeIcon icon={faAngleDoubleLeft} /> BACK
                    </Link>
                </span>
            );

            const materialColumns = getColumnsByGroup({
                group: groupByGroups.MATERIAL,
                header: materialHeader,
                override: actions.includes('override') && AAReplen !== 'AA',
                unitType
            });

            const storeColumns = getColumnsByGroup({
                group: groupByGroups.MATERIAL_STORE,
                override: actions.includes('override'),
                unitType
            });

            return {
                unitType,
                AAReplen,
                materialColumns,
                storeColumns,
                totalWidth: getTotalColumnsWidth(materialColumns)
            };
        }

        return null;
    }

    componentDidMount() {
        const { getMaterial, materialCode } = this.props;
        getMaterial(materialCode);
    }

    handleSorting = sort => {
        const { setSorting, getMaterial, materialCode } = this.props;
        setSorting(sort);
        getMaterial(materialCode);
    };

    render() {
        const { material, loading, sorting, actions, getMaterial, materialCode } = this.props;
        const { materialColumns, storeColumns, totalWidth } = this.state;
        const disabled = !actions.includes('override');

        if (!material) {
            return null;
        }

        return (
            <div className={cn('view-material')}>
                <div className="view-material__sidebar">
                    <UnitToggleContainer
                        orientation="vertical"
                        fixedRegion="local"
                        onToggle={() => getMaterial(materialCode)}
                    />
                </div>
                <div className="view-material__tables">
                    <div className="view-material__table">
                        <Table
                            className="view-material__table-fixed"
                            data={[{ ...material, disabled }]}
                            columns={materialColumns}
                            manual
                            resizable={false}
                            showPageJump={false}
                            pageSize={1}
                            sortable={false}
                            noDataText={
                                <div className="view-shipplan__no-results">
                                    <h3>No material data found</h3>
                                </div>
                            }
                            showPagination={false}
                            showPageSizeOptions={false}
                            loading={loading}
                            style={{
                                height: '84px',
                                minWidth: totalWidth
                            }}
                        />
                        <Table
                            className="view-material__table-body -striped -highlight"
                            data={material.stores}
                            columns={storeColumns}
                            manual
                            showPageJump={false}
                            resizable={false}
                            noDataText={
                                <div className="view-shipplan__no-results">
                                    <h3>No material data found</h3>
                                    <p>Make sure you have:</p>
                                    <ul>
                                        <li>
                                            added all the required files to create a ship plan{' '}
                                            <Link to="create-shipplan">here</Link>
                                        </li>
                                        <li>
                                            taken a look at the <Link to="documentation">documentation</Link>
                                        </li>
                                    </ul>
                                </div>
                            }
                            showPagination={false}
                            showPaginationTop={false}
                            paginationBottom={false}
                            defaultSorted={sorting}
                            onSortedChange={this.handleSorting}
                            onPageChange={this.handlePageChange}
                            showPageSizeOptions={false}
                            loading={loading}
                            style={{ minWidth: totalWidth + 16 }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewMaterial;
