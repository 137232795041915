import partnerProposalsService from '../services/partnerProposalsService';
import {
    CREATE_PARTNER_PROPOSALS,
    CREATE_PARTNER_PROPOSALS_FAILURE,
    CREATE_PARTNER_PROPOSALS_SUCCESS,
    APPLY_PARTNER_PROPOSALS,
    APPLY_PARTNER_PROPOSALS_SUCCESS,
    APPLY_PARTNER_PROPOSALS_FAILURE,
    UPLOAD_PARTNER_PROPOSAL_SUCCESS,
    UPLOAD_PARTNER_PROPOSAL_FAILURE
} from './actionTypes';

export const createPartnerProposalsSuccess = () => ({ type: CREATE_PARTNER_PROPOSALS_SUCCESS });
export const createPartnerProposalsFailure = error => ({ type: CREATE_PARTNER_PROPOSALS_FAILURE, error });

export const createPartnerProposals = () => async dispatch => {
    const action = { type: CREATE_PARTNER_PROPOSALS };
    dispatch(action);
    try {
        await partnerProposalsService.create();
        dispatch(createPartnerProposalsSuccess());
    } catch (err) {
        dispatch(createPartnerProposalsFailure(err.message));
    }
};

export const applyPartnerProposalsSuccess = () => ({ type: APPLY_PARTNER_PROPOSALS_SUCCESS });
export const applyPartnerProposalsFailure = error => ({ type: APPLY_PARTNER_PROPOSALS_FAILURE, error });
export const applyPartnerProposals = () => async dispatch => {
    const action = { type: APPLY_PARTNER_PROPOSALS };
    try {
        dispatch(action);
        await partnerProposalsService.apply();

        dispatch(applyPartnerProposalsSuccess());
    } catch (err) {
        dispatch(applyPartnerProposalsFailure(err.message));
        throw err;
    }
};

export const uploadPartnerProposalSuccess = () => ({ type: UPLOAD_PARTNER_PROPOSAL_SUCCESS });
export const uploadPartnerProposalFailure = ({ message, meta }) => ({
    // Map error to serializable object for state persistency:
    type: UPLOAD_PARTNER_PROPOSAL_FAILURE,
    error: {
        message,
        validationErrors: (meta || {}).validationErrors
    }
});
