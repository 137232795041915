import React from 'react';
import { oneOfType, arrayOf, node, string, bool } from 'prop-types';
import cn from 'classnames';
import './Stepper.scss';

const Stepper = ({ children }) => <ul className="stepper">{children}</ul>;

Stepper.propTypes = {
    children: oneOfType([arrayOf(node), node]).isRequired
};

const Step = ({ children, title, indicator, last, 'data-cy': dataCy }) => (
    <li className={cn('step', { 'step--last': last })} data-cy={dataCy}>
        <div className="step__header">
            <span className="step__indicator">{indicator}</span>
            <span className="step__title" data-cy="step-title">
                {title}
            </span>
        </div>
        <div className="step__content">{children}</div>
    </li>
);

Step.propTypes = {
    children: oneOfType([arrayOf(node), node]),
    indicator: node.isRequired,
    title: string,
    last: bool,
    'data-cy': string
};

Step.defaultProps = {
    children: null,
    title: '',
    last: false
};

export { Stepper as default, Step };
