import { get } from 'lodash';
import overrideService from '../services/overrideService';
import {
    OVERRIDE,
    OVERRIDE_FAILURE,
    OVERRIDE_SUCCESS,
    SET_INVALID_OVERRIDES,
    CLEAR_INVALID_OVERRIDES,
    DELETE_INVALID_OVERRIDES,
    DELETE_INVALID_OVERRIDES_FAILURE,
    DELETE_INVALID_OVERRIDES_SUCCESS
} from './actionTypes';

export const setInvalidOverrides = (invalidOverridesAction, invalidOverridesError, invalidOverrides) => ({
    type: SET_INVALID_OVERRIDES,
    invalidOverridesAction,
    invalidOverridesError,
    invalidOverrides
});
export const clearInvalidOverrides = () => ({ type: CLEAR_INVALID_OVERRIDES });

export const deleteInvalidOverridesSuccess = () => ({ type: DELETE_INVALID_OVERRIDES_SUCCESS });
export const deleteInvalidOverridesFailure = () => ({ type: DELETE_INVALID_OVERRIDES_FAILURE });
export const deleteInvalidOverrides = materials => async dispatch => {
    const action = { type: DELETE_INVALID_OVERRIDES };
    dispatch(action);
    try {
        await overrideService.deleteAll({ Materials: materials });
        dispatch(deleteInvalidOverridesSuccess());
    } catch (err) {
        dispatch(deleteInvalidOverridesFailure());
    }
};

export const overrideSuccess = materialInfo => ({ type: OVERRIDE_SUCCESS, materialInfo });
export const overrideFailure = () => ({ type: OVERRIDE_FAILURE });

const override = data => async dispatch => {
    const action = { type: OVERRIDE, materialCode: get(data, 'Material', null) };
    dispatch(action);
    try {
        const materialInfo = await overrideService.update(data);
        dispatch(overrideSuccess(materialInfo));
    } catch (err) {
        dispatch(overrideFailure());
        throw err;
    }
};

const overrideDelete = data => async dispatch => {
    const action = { type: OVERRIDE, materialCode: get(data, 'Material', null) };
    dispatch(action);
    try {
        const materialInfo = await overrideService.delete(data);
        dispatch(overrideSuccess(materialInfo));
    } catch (err) {
        dispatch(overrideFailure());
        throw err;
    }
};

export { overrideDelete, override as overrideMaterialStore, override as overrideMaterial };
