import { compose } from 'redux';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { func, shape } from 'prop-types';
import io from 'socket.io-client';
import Loader from '../components/Loader/Loader';
import { setSeasonFromSocket as setSeasonFromSocketAction, getSeason as getSeasonAction } from '../actions/season';

const socket = io(process.env.REACT_APP_API_ENDPOINT);

export function withSeason(WrappedComponent) {
    return class extends PureComponent {
        static propTypes = {
            setSeasonFromSocket: func.isRequired,
            getSeason: func.isRequired,
            season: shape({})
        };

        componentDidMount() {
            const { setSeasonFromSocket, getSeason } = this.props;
            getSeason();
            socket.on('season-update', ({ season }) => {
                setSeasonFromSocket(season);
            });
        }

        componentWillUnmount() {
            socket.removeListener('season-update');
        }

        render() {
            const { season } = this.props;
            if (!season) return <Loader />;
            return <WrappedComponent {...this.props} />;
        }
    };
}

const mapDispatchToProps = {
    setSeasonFromSocket: setSeasonFromSocketAction,
    getSeason: getSeasonAction
};

const mapStateToProps = ({ season: { season } }) => ({ season });

export default compose(connect(mapStateToProps, mapDispatchToProps), withSeason);
