import {
    GET_SEASON,
    GET_SEASON_FAILURE,
    GET_SEASON_SUCCESS,
    SET_SEASON_FROM_SOCKET,
    NEW_SEASON,
    NEW_SEASON_SUCCESS,
    NEW_SEASON_FAILURE,
    EDIT_SEASON,
    EDIT_SEASON_SUCCESS,
    EDIT_SEASON_FAILURE
} from '../actions/actionTypes';
import createReducer from './createReducer';

export const initialState = {
    season: undefined,
    getSeasonSuccess: false,
    getSeasonError: false,
    newSeasonError: null,
    newSeasonSuccess: false,
    newSeasonLoading: false,
    editSeasonError: null,
    editSeasonSuccess: false,
    editSeasonLoading: false
};

const ACTION_HANDLERS = {
    [GET_SEASON]: () => ({ getSeasonSuccess: false, getSeasonError: undefined }),
    [GET_SEASON_FAILURE]: (state, { error }) => ({ getSeasonError: error }),
    [GET_SEASON_SUCCESS]: (state, { season }) => ({ getSeasonSuccess: true, season }),
    [SET_SEASON_FROM_SOCKET]: (state, { season }) => ({ getSeasonSuccess: true, season }),
    [NEW_SEASON]: () => ({ newSeasonError: null, newSeasonLoading: true, newSeasonSuccess: false }),
    [NEW_SEASON_SUCCESS]: () => ({
        newSeasonError: null,
        newSeasonLoading: false,
        newSeasonSuccess: true,
        season: undefined
    }),
    [NEW_SEASON_FAILURE]: (state, { error }) => ({ newSeasonError: error, newSeasonLoading: false }),
    [EDIT_SEASON]: () => ({ editSeasonError: null, editSeasonLoading: true, editSeasonSuccess: false }),
    [EDIT_SEASON_SUCCESS]: () => ({
        editSeasonError: null,
        editSeasonLoading: false,
        editSeasonSuccess: true,
        season: undefined
    }),
    [EDIT_SEASON_FAILURE]: (state, { error }) => ({ editSeasonError: error, editSeasonLoading: false })
};

export default createReducer(initialState, ACTION_HANDLERS);
