import React from 'react';
import { func, object, arrayOf, string } from 'prop-types';
import { get } from 'lodash';
import { Step } from '../Stepper/Stepper';
import StepIndicator, { INDICATOR_IMPORTING, INDICATOR_COMPLETED } from '../StepIndicator/StepIndicator';
import SourceFile, { fileTypes } from '../SourceFile/SourceFile';
import './FileUploadStep.scss';
import SyncPricesContainer from '../SyncPrices/SyncPricesContainer';

const FileUploadStep = ({
    buyplan,
    orderbook,
    aareplen,
    storeranks,
    carryovers,
    getSeason,
    setInvalidOverrides,
    actions
}) => {
    const files = [buyplan, orderbook, aareplen, storeranks, carryovers];
    const completedParts = files.filter(x => !(x === undefined || x === null)).length;
    const totalParts = files.length;
    const state = completedParts < totalParts ? INDICATOR_IMPORTING : INDICATOR_COMPLETED;
    const allowReupload = actions.includes('addSourceFile');

    const handleError = (filename, error) => {
        const invalidOverrides = get(error, 'meta.invalidOverrides', []);
        if (invalidOverrides.length > 0) {
            setInvalidOverrides(`uploading ${filename}`, error.message, invalidOverrides);
        }
    };

    return (
        <Step
            title="Upload files"
            indicator={<StepIndicator state={state} completedParts={completedParts} totalParts={totalParts} />}
        >
            <div className="FileUploadStep">
                <SourceFile
                    title="Buyplan"
                    type={fileTypes.CSV}
                    file={buyplan}
                    endpoint="/seasons/current/buyplan"
                    getSeason={getSeason}
                    onError={handleError}
                    allowReupload={allowReupload}
                />
                <SourceFile
                    title="Order book"
                    type={fileTypes.XLSX}
                    file={orderbook}
                    endpoint="/seasons/current/orderbook"
                    getSeason={getSeason}
                    onError={handleError}
                    allowReupload={allowReupload}
                />
                <SourceFile
                    title="Carryover"
                    type={fileTypes.XLSX}
                    file={carryovers}
                    endpoint="/seasons/current/carryovers"
                    getSeason={getSeason}
                    onError={handleError}
                    allowReupload={allowReupload}
                />
                <SourceFile
                    title="AA & Replen"
                    type={fileTypes.XLSX}
                    file={aareplen}
                    endpoint="/seasons/current/aareplen"
                    getSeason={getSeason}
                    onError={handleError}
                    allowReupload={allowReupload}
                />
                <SourceFile
                    title="Store ranking"
                    type={fileTypes.XLSX}
                    file={storeranks}
                    endpoint="/seasons/current/storeranks"
                    getSeason={getSeason}
                    onError={handleError}
                    allowReupload={allowReupload}
                />
                <SyncPricesContainer />
            </div>
        </Step>
    );
};

FileUploadStep.propTypes = {
    getSeason: func.isRequired,
    setInvalidOverrides: func.isRequired,
    orderbook: object,
    carryovers: object,
    storeranks: object,
    aareplen: object,
    buyplan: object,
    actions: arrayOf(string)
};

FileUploadStep.defaultProps = {
    actions: []
};

export default FileUploadStep;
