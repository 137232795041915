import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import exchangeRatesService from '../../services/exchangeRatesService';
import Loader from '../Loader/Loader';
import './ExchangeRates.scss';

export default class Settings extends Component {
    static propTypes = {};

    state = {
        loading: false,
        error: null,
        exchangeRates: {}
    };

    componentDidMount() {
        this.getExchangeRates();
    }

    getExchangeRates = async () => {
        this.setState({ loading: true, error: null });
        try {
            const exchangeRates = await exchangeRatesService.get();
            this.setState({ exchangeRates, loading: false });
        } catch (err) {
            this.setState({ loading: false, error: err.message });
        }
    };

    render() {
        const { loading, error, exchangeRates } = this.state;
        return (
            <div className="ExchangeRates">
                <Container>
                    <Row>
                        <Col xs={{ span: 8, offset: 2 }}>
                            <h1>
                                Exchange rates FY20
                                {loading && <Loader width={16} />}
                            </h1>
                            {error && (
                                <div className="ExchangeRates__error">
                                    <FontAwesomeIcon icon={faExclamationTriangle} /> {error}
                                </div>
                            )}
                            {Object.entries(exchangeRates).length > 0 && (
                                <table className="table table-hover">
                                    <caption>
                                        If these exchange rates are outdated and/or should be changed, please{' '}
                                        <Link to="/documentation/support">log a ticket</Link>
                                    </caption>
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">Currency</th>
                                            <th scope="col">Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {Object.entries(exchangeRates).map(setting => (
                                            <tr key={setting[0]}>
                                                <Fragment>
                                                    <th scope="row">{setting[0]}</th>
                                                    <td>$ {setting[1]}</td>
                                                </Fragment>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
