import React from 'react';
import { bool, func, string, node, object } from 'prop-types';
import cn from 'classnames';
import './Button.scss';

const Button = ({ className, onClick, large, children, disabled, style, ...props }) => (
    <button
        style={style}
        disabled={disabled}
        onClick={onClick}
        className={cn(className, 'Button', { 'Button-large': large })}
        type="button"
        {...props}
    >
        {children}
        <span className="Button__inner" />
    </button>
);

Button.propTypes = {
    children: node.isRequired,
    onClick: func,
    className: string,
    large: bool,
    disabled: bool,
    style: object
};

Button.defaultProps = {
    onClick: () => null,
    className: '',
    large: false,
    disabled: false,
    style: {}
};

export default Button;
