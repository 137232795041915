import { connect } from 'react-redux';
import {
    clearInvalidOverrides as clearInvalidOverridesAction,
    deleteInvalidOverrides as deleteInvalidOverridesAction
} from '../../actions/override';
import InvalidOverridesModal from './InvalidOverridesModal';

const mapActionCreators = {
    clearInvalidOverrides: clearInvalidOverridesAction,
    deleteInvalidOverrides: deleteInvalidOverridesAction
};

const mapStateToProps = ({
    override: { invalidOverrides, invalidOverridesAction, invalidOverridesError, deletingInvalidOverrides }
}) => ({
    invalidOverrides,
    invalidOverridesAction,
    invalidOverridesError,
    deletingInvalidOverrides
});

export default connect(mapStateToProps, mapActionCreators)(InvalidOverridesModal);
