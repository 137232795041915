import React, { Component, Fragment } from 'react';
import { arrayOf, shape, string, object, func, number, any, bool } from 'prop-types';
import cn from 'classnames';
import Loader from '../Loader/Loader';
import Button from '../Button/Button';
import Filter from './Filter';
import './FilterGroup.scss';

class FilterGroup extends Component {
    componentWillMount() {
        const { getShipplanMetaData } = this.props;
        getShipplanMetaData();
    }

    handleClearFilter = () => {
        const { clearFilter, getShipplan } = this.props;
        clearFilter();
        getShipplan(0);
    };

    handleSetFilter(field, value) {
        const { setFilter, getShipplan } = this.props;
        setFilter({ [field]: value });
        getShipplan(0);
    }

    render() {
        const { filters, activeFilters, activeFilterAmount, metaDataPending } = this.props;

        return (
            <Fragment>
                <div className={cn('filter-group-wrapper', { 'filter-group-wrapper--loading': metaDataPending })}>
                    {metaDataPending && <Loader width={120} />}
                    <div className="filter-group">
                        {activeFilterAmount > 0 && (
                            <Button className="Button-fullWidth Button__inverted" onClick={this.handleClearFilter}>
                                remove all filters ({activeFilterAmount})
                            </Button>
                        )}
                        {filters && filters.length > 0 ? (
                            filters.map(filter => (
                                <Filter
                                    key={filter.field}
                                    setFilter={values => this.handleSetFilter(filter.field, values)}
                                    activeFilter={activeFilters[filter.field]}
                                    {...filter}
                                />
                            ))
                        ) : (
                            <div className="filter-group-spinner">
                                <Loader />
                            </div>
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}

FilterGroup.propTypes = {
    getShipplanMetaData: func.isRequired,
    filters: arrayOf(
        shape({
            field: string,
            filterData: arrayOf(string),
            filterType: string,
            label: string
        })
    ),
    activeFilters: object,
    activeFilterAmount: number,
    setFilter: func.isRequired,
    clearFilter: func.isRequired,
    metaDataPending: bool,
    rows: number,
    url: any,
    getShipplan: func
};

FilterGroup.defaultProps = {
    filters: [],
    activeFilters: {},
    metaDataPending: false
};

export default FilterGroup;
