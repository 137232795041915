import React, { Component } from 'react';
import { bool, string, func } from 'prop-types';
import cn from 'classnames';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';

class ConfirmButton extends Component {
    state = {
        confirmed: false
    };

    handleClick = () => {
        const { onClick } = this.props;
        const { confirmed } = this.state;
        if (confirmed) {
            onClick();
        }
        this.setState({ confirmed: !confirmed });
    };

    render() {
        const { label, confirmLabel, loading, disabled, ...props } = this.props;
        const { confirmed } = this.state;
        const text = confirmed ? confirmLabel : label;
        return (
            <Button
                {...props}
                disabled={disabled || loading}
                onClick={this.handleClick}
                className={cn({ 'Button-done': confirmed })}
            >
                {text} {loading && <Loader width={16} />}
            </Button>
        );
    }
}

ConfirmButton.propTypes = {
    onClick: func,
    label: string.isRequired,
    confirmLabel: string.isRequired,
    loading: bool,
    disabled: bool
};

ConfirmButton.defaultProps = {};

export default ConfirmButton;
