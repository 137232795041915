import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import { getMaterial, setSorting } from '../../actions/material';
import withSeason from '../../hoc/withSeason';
import ViewMaterial from './ViewMaterial';

const mapDispatchToProps = {
    getMaterial,
    setSorting
};

const mapStateToProps = ({ material, shipplan, season }, { match }) => ({
    materialCode: match.params.material,
    material: material.material,
    loading: material.getMaterialPending,
    items: material.items,
    sorting: material.sorting,
    unitType: shipplan.unitType,
    actions: get(season, 'season.actions', [])
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withSeason)(ViewMaterial);
