import { connect } from 'react-redux';
import SyncPrices from './SyncPrices';

const mapActionCreators = {};

const mapStateToProps = state => ({
    priceSyncDate: state.season.season.priceSyncDate,
    actions: state.season.season.actions
});

export default connect(mapStateToProps, mapActionCreators)(SyncPrices);
