import React, { Component } from 'react';
import { string, array } from 'prop-types';
import moment from 'moment';
import './SyncPrices.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import seasonService from '../../services/seasonService';

export default class SyncPrices extends Component {
    static propTypes = {
        priceSyncDate: string,
        actions: array
    };

    static defaultProps = {
        actions: []
    };

    state = {
        loading: false,
        error: null
    };

    syncPrices = async () => {
        const { loading } = this.state;
        if (loading) return;
        this.setState({ loading: true, error: null });
        try {
            await seasonService.syncPrices();
            this.setState({ loading: false });
        } catch (err) {
            this.setState({ loading: false, error: err.message });
        }
    };

    render() {
        const { priceSyncDate, actions } = this.props;
        const { loading, error } = this.state;
        return (
            <div className="SyncPrices">
                <FontAwesomeIcon icon={faDollarSign} />
                <span className="SyncPrices__name">Pricing</span>
                <span className="SyncPrices__date">
                    {priceSyncDate ? moment(priceSyncDate).calendar() : '- not yet imported'}
                </span>
                <br />
                {actions.includes('viewShipplan') && (
                    <Button disabled={loading} className="Button__inverted" onClick={this.syncPrices}>
                        Get latest prices {loading && <Loader width={16} />}
                    </Button>
                )}
                {error && (
                    <div className="SyncPrices__error">
                        <FontAwesomeIcon icon={faExclamationTriangle} /> {error}
                    </div>
                )}
            </div>
        );
    }
}
