import { connect } from 'react-redux';
import { getSeason } from '../../actions/season';
import SeasonStep from './SeasonStep';

const mapDispatchToProps = {
    getSeason
};

const mapStateToProps = ({ season: { season } }) => ({ season });

export default connect(mapStateToProps, mapDispatchToProps)(SeasonStep);
