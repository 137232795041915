import { get } from 'lodash';
import fileSaver from 'file-saver';

let auth;

/** This is the okta-react Auth object, we use this to ensure a valid
 * token on each request, it is being set from routes.js
 */
export function setAuth(authObject) {
    auth = authObject;
}

/** A synchronous way to grab the access token, does not validate expiration
 * this is used in components that render a download link, with the bearer in the URL.
 */
export function getToken() {
    try {
        const oktaTokenStorage = window.localStorage.getItem('okta-token-storage');
        const oktaTokenJSON = JSON.parse(oktaTokenStorage);
        const token = oktaTokenJSON.accessToken.accessToken;
        return token;
    } catch (error) {
        return null;
    }
}

export const getMethod = method => method || 'GET';

export const getBody = ({ data, file }) => {
    if (file) {
        return file;
    }
    return data ? JSON.stringify(data) : undefined;
};

export const parseJSON = response =>
    response
        .json()
        .then(data => ({ data, response }))
        .catch(() => Promise.resolve({ data: null, response }));

export const checkStatus = ({ data, response }) =>
    new Promise((resolve, reject) => {
        if (response.status >= 200 && response.status < 300) {
            resolve(data);
        } else {
            const error = new Error(get(data, 'error.message', null) || response.statusText);
            error.response = response;
            error.meta = get(data, 'error.meta', undefined);
            reject(error);
        }
    });

async function request(options) {
    const { url, method, signal, noContentType, authorization = true } = options;
    const presetHeaders = {};
    if (!noContentType) {
        presetHeaders['Content-Type'] = 'application/json';
    }

    if (authorization) {
        presetHeaders.Authorization = `Bearer ${await auth.getAccessToken()}`;
    }

    const headers = options.headers ? { ...presetHeaders, ...options.headers } : presetHeaders;
    const body = getBody(options);

    return fetch(url, {
        method: getMethod(method),
        body,
        headers,
        signal
    })
        .then(parseJSON)
        .then(checkStatus)
        .then(responseData => Promise.resolve(responseData))
        .catch(err => {
            if (get(err, 'response.status', null) === 401) {
                window.location = '/need-authorization';
            } else {
                throw err;
            }
        });
}

export async function download(options) {
    const { url, filename } = options;
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: `Bearer ${await auth.getAccessToken()}`
    };

    return fetch(url, {
        method: 'GET',
        headers
    })
        .then(response => response.blob())
        .then(blob => fileSaver.saveAs(blob, filename));
}

export default request;
