import request from '../api/api';

const get = () =>
    request({
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/seasons/current/exchange-rates`
    });

export default {
    get
};
