import React from 'react';
import { arrayOf, string, func, bool } from 'prop-types';
import Modal from '../Modal/Modal';
import InvalidOverrides from './InvalidOverrides';

const InvalidOverridesModal = ({
    invalidOverrides,
    invalidOverridesAction,
    invalidOverridesError,
    deletingInvalidOverrides,
    clearInvalidOverrides,
    deleteInvalidOverrides
}) => {
    const modalIsOpen = invalidOverrides.length > 0;
    const handleDelete = () => deleteInvalidOverrides(invalidOverrides);
    return (
        <Modal
            isOpen={modalIsOpen}
            closeAction={clearInvalidOverrides}
            title={invalidOverridesError}
            subtitle={`CONFLICTING MANUAL OVERRIDE${invalidOverrides.length > 1 ? 'S' : ''}`}
        >
            {modalIsOpen && (
                <div className="update-flowdate">
                    <InvalidOverrides
                        invalidOverrides={invalidOverrides}
                        invalidOverridesAction={invalidOverridesAction}
                        deletingInvalidOverrides={deletingInvalidOverrides}
                        onClose={clearInvalidOverrides}
                        onDelete={handleDelete}
                    />
                </div>
            )}
        </Modal>
    );
};

InvalidOverridesModal.propTypes = {
    clearInvalidOverrides: func.isRequired,
    deleteInvalidOverrides: func.isRequired,
    invalidOverrides: arrayOf(string),
    invalidOverridesAction: string,
    invalidOverridesError: string,
    deletingInvalidOverrides: bool
};

export default InvalidOverridesModal;
