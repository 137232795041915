import React, { Component } from 'react';
import { string, shape, func, arrayOf } from 'prop-types';
import io from 'socket.io-client';
import qs from 'qs';
import Button from '../Button/Button';
import { Step } from '../Stepper/Stepper';
import StepIndicator from '../StepIndicator/StepIndicator';
import { getToken } from '../../api/api';

const socket = io(process.env.REACT_APP_API_ENDPOINT);

export default class ShipplanStep extends Component {
    static propTypes = {
        season: shape({
            status: string,
            actions: arrayOf(string)
        }),
        history: shape({
            push: func
        })
    };

    static defaultProps = {
        season: {
            status: undefined
        }
    };

    state = {
        progress: undefined
    };

    componentDidMount() {
        socket.on('shipplan-progress', ({ progress }) => {
            this.setState({ progress });
        });
    }

    componentWillUnmount() {
        socket.removeListener('shipplan-progress');
    }

    handleViewShipplanClick = () => {
        const { history } = this.props;
        history.push('/view-shipplan');
    };

    render() {
        const { progress } = this.state;
        const { season } = this.props;

        const { status } = season;

        let shipplanStatus;

        if (status === 'shipplanCreating') {
            shipplanStatus = 'processing';
        } else if (season.actions.includes('viewShipplan')) {
            shipplanStatus = 'completed';
        }

        const qsParams = { Authorization: `Bearer ${getToken()}` };
        const exportUrl = `${
            process.env.REACT_APP_API_ENDPOINT
        }/seasons/current/shipplan/download/material_store?${qs.stringify(qsParams)}`;

        return (
            <Step title="Ship plan" indicator={<StepIndicator state={shipplanStatus} percentage={progress} />}>
                {season.actions.includes('viewShipplan') && (
                    <p>
                        <Button onClick={this.handleViewShipplanClick}>View Ship plan</Button>
                        <br />
                        Export Ship plan:
                        <br />
                        <a href={exportUrl} download className="Button__inverted Button">
                            Material / Store level
                        </a>
                    </p>
                )}
            </Step>
        );
    }
}
