import shipplanService from '../services/shipplanService';
import { GET_MATERIAL, GET_MATERIAL_FAILURE, GET_MATERIAL_SUCCESS, MATERIAL_SET_SORTING } from './actionTypes';

export const getMaterialSuccess = material => ({ type: GET_MATERIAL_SUCCESS, material });

export const getMaterialFailure = error => ({ type: GET_MATERIAL_FAILURE, error });

export const getMaterial = materialCode => async (dispatch, getState) => {
    const state = getState();
    const { material, unitToggle } = state;
    const { sorting } = material;
    const { unitType, priceModel, priceRegion } = unitToggle;
    try {
        dispatch({ type: GET_MATERIAL });
        const response = await shipplanService.getMaterial(materialCode, sorting, unitType, priceModel, priceRegion);
        dispatch(getMaterialSuccess(response));
    } catch (err) {
        dispatch(getMaterialFailure(err.message));
    }
};

export const setSorting = sorting => ({ type: MATERIAL_SET_SORTING, sorting });
