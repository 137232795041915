import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { shape, func, string, bool, arrayOf } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import SeasonForm from '../SeasonForm/SeasonForm';
import { suggestNewSeason, formatDateToString } from './season-helper';
import './NewSeason.scss';

export default class NewSeason extends Component {
    static propTypes = {
        newSeason: func.isRequired,
        currentSeason: shape({}).isRequired,
        history: shape({ push: func }).isRequired,
        actions: arrayOf(string),
        error: string,
        loading: bool,
        success: bool
    };

    state = {
        newSeasonSuggestion: null
    };

    componentDidMount = async () => {
        const { currentSeason } = this.props;
        const newSeasonSuggestion = suggestNewSeason(currentSeason);
        this.setState({ newSeasonSuggestion });
    };

    handleSubmit = async data => {
        const { newSeason, history } = this.props;
        const { name, startDate, flow2StartDate, flow3StartDate, endDate } = data;
        try {
            await newSeason({
                name,
                startDate: formatDateToString(startDate),
                flow2StartDate: formatDateToString(flow2StartDate),
                flow3StartDate: formatDateToString(flow3StartDate),
                endDate: formatDateToString(endDate)
            });
            history.push('/create-shipplan');
        } catch (err) {
            // Feedback is on its way...
        }
    };

    render() {
        const { error, loading, success, actions } = this.props;
        const { newSeasonSuggestion } = this.state;
        return (
            <Container>
                <Row>
                    <Col xs={{ span: 8, offset: 2 }}>
                        <h1>NEW SEASON</h1>
                        <div className="NewSeason__warningDialog">
                            <h3>
                                <FontAwesomeIcon icon={faExclamationTriangle} /> CAUTION
                            </h3>
                            <p>
                                Please keep in mind that this process is irreversible and will{' '}
                                <strong>remove all data</strong>
                                {'  '}
                                that is currently stored in the application. This includes all uploaded files and overrides
                                made by users.
                            </p>
                        </div>
                        {newSeasonSuggestion && (
                            <SeasonForm
                                onSubmit={this.handleSubmit}
                                defaultValues={newSeasonSuggestion}
                                error={error}
                                buttonLabel="Start new season"
                                confirmButtonLabel="Really start a new season?"
                                loading={loading}
                                success={success}
                                disabled={!actions.includes('destroySeason')}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        );
    }
}
