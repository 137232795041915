import { get } from 'lodash';
import {
    OVERRIDE,
    OVERRIDE_FAILURE,
    OVERRIDE_SUCCESS,
    SET_INVALID_OVERRIDES,
    CLEAR_INVALID_OVERRIDES,
    DELETE_INVALID_OVERRIDES,
    DELETE_INVALID_OVERRIDES_FAILURE,
    DELETE_INVALID_OVERRIDES_SUCCESS,
    NEW_SEASON_FAILURE,
    EDIT_SEASON_FAILURE
} from '../actions/actionTypes';
import createReducer from './createReducer';

export const initialState = {
    updating: null,
    invalidOverrides: [],
    invalidOverridesAction: null,
    invalidOverridesError: null,
    deletingInvalidOverrides: false
};

const ACTION_HANDLERS = {
    [OVERRIDE]: (state, { materialCode }) => ({ updating: materialCode }),
    [OVERRIDE_SUCCESS]: () => ({ updating: null }),
    [OVERRIDE_FAILURE]: () => ({ updating: null }),
    [SET_INVALID_OVERRIDES]: (state, { invalidOverrides, invalidOverridesAction, invalidOverridesError }) => ({
        invalidOverrides,
        invalidOverridesAction,
        invalidOverridesError
    }),
    [CLEAR_INVALID_OVERRIDES]: () => ({ invalidOverrides: [], invalidOverridesError: null, invalidOverridesAction: null }),
    [DELETE_INVALID_OVERRIDES]: () => ({ deletingInvalidOverrides: true }),
    [DELETE_INVALID_OVERRIDES_FAILURE]: () => ({ deletingInvalidOverrides: false }),
    [DELETE_INVALID_OVERRIDES_SUCCESS]: () => ({
        deletingInvalidOverrides: false,
        invalidOverrides: [],
        invalidOverridesAction: null,
        invalidOverridesError: null
    }),
    [NEW_SEASON_FAILURE]: (state, action) => ({ invalidOverrides: get(action, 'errorMeta.invalidOverrides', []) }),
    [EDIT_SEASON_FAILURE]: (state, action) => ({ invalidOverrides: get(action, 'errorMeta.invalidOverrides', []) })
};

export const isUpdating = (state, material) => state.override && state.override.updating === material;

export default createReducer(initialState, ACTION_HANDLERS);
