import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Stepper from '../Stepper/Stepper';
import withSeason from '../../hoc/withSeason';
import ShipplanStepContainer from './ShipplanStepContainer';
import SeasonStepContainer from './SeasonStepContainer';
import FileUploadStepContainer from './FileUploadStepContainer';
import CreatePartnerProposalsStepContainer from './CreatePartnerProposalsStepContainer';
import StartNewSeasonStepContainer from './StartNewSeasonStepContainer';

export const CreateShipplan = () => (
    <Container>
        <Row>
            <Col xs={{ span: 8, offset: 2 }}>
                <h1>CREATE SHIP PLAN</h1>
                <Stepper>
                    <SeasonStepContainer />
                    <FileUploadStepContainer />
                    <ShipplanStepContainer />
                    <CreatePartnerProposalsStepContainer />
                    <StartNewSeasonStepContainer />
                </Stepper>
            </Col>
        </Row>
    </Container>
);

export default withSeason(CreateShipplan);
