import React from 'react';
import { arrayOf, string, oneOf, shape } from 'prop-types';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle, faCircleNotch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import './PartnerProposalsList.scss';

const iconMap = {
    completed: { icon: faCheckCircle },
    waiting: { icon: faCircle },
    loading: { icon: faCircleNotch, spin: true },
    failed: { icon: faTimesCircle }
};

const PartnerProposalItem = ({ partnerProposals }) => (
    <ul className="PartnerProposalsList">
        {sortBy(partnerProposals, 'partnername').map(({ partnername, status, updatedAt }) => (
            <li
                key={partnername}
                className={classNames('PartnerProposalsList__item', `PartnerProposalsList__item--${status}`)}
            >
                <FontAwesomeIcon {...iconMap[status]} /> {partnername}{' '}
                {status === 'completed' && updatedAt && (
                    <span className="PartnerProposalsList__updated">- {moment.unix(updatedAt).calendar()}</span>
                )}
            </li>
        ))}
    </ul>
);

PartnerProposalItem.propTypes = {
    partnerProposals: arrayOf(
        shape({
            partnername: string,
            status: oneOf(['waiting', 'completed', 'loading', 'failed']),
            updatedAt: string
        })
    )
};

export default PartnerProposalItem;
