import React from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Security, SecureRoute, ImplicitCallback, Auth } from '@okta/okta-react';
import CreateShipplan from '../components/CreateShipplan/CreateShipplan';
import ViewShipplanContainer from '../components/ViewShipplan/ViewShipplanContainer';
import Layout from '../components/Layout/Layout';
import ViewMaterialContainer from '../components/ViewMaterial/ViewMaterialContainer';
import NeedAuth from '../components/Auth/Auth';
import { setAuth } from '../api/api';
import NewSeasonContainer from '../components/NewSeason/NewSeasonContainer';
import EditSeasonContainer from '../components/EditSeason/EditSeasonContainer';
import InvalidOverridesContainer from '../components/InvalidOverrides/InvalidOverridesContainer';
import Documentation from '../components/Documentation/Documentation';
import PartnerProposalContainer from '../components/PartnerProposals/PartnerProposalContainer';
import ExchangeRates from '../components/ExchangeRates/ExchangeRates';
import InvalidPartnerProposalContainer from '../components/InvalidPartnerProposal/InvalidPartnerProposalContainer';
import GlobalErrorContainer from '../components/GlobalError/GlobalErrorContainer';

const auth = new Auth({
    history: createBrowserHistory(),
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    client_id: process.env.REACT_APP_OKTA_CLIENTID,
    redirect_uri: `${window.location.origin}/implicit/callback`,
    response_type: 'token'
});
setAuth(auth);

const Routes = () => (
    <BrowserRouter>
        <Layout>
            <InvalidOverridesContainer />
            <InvalidPartnerProposalContainer />
            <GlobalErrorContainer />
            <div className="route-container">
                <Security auth={auth}>
                    <Switch>
                        <SecureRoute exact path="/create-shipplan" component={CreateShipplan} />
                        <SecureRoute exact path="/view-shipplan/" component={ViewShipplanContainer} />
                        <SecureRoute exact path="/view-shipplan/:material" component={ViewMaterialContainer} />
                        <SecureRoute exact path="/season/new" component={NewSeasonContainer} />
                        <SecureRoute exact path="/season/edit" component={EditSeasonContainer} />
                        <SecureRoute exact path="/partner-proposals/" component={PartnerProposalContainer} />
                        <Route path="/exchange-rates" component={ExchangeRates} />
                        <Route path="/documentation" component={Documentation} />
                        <Route path="/need-authorization" component={NeedAuth} />
                        <Route path="/implicit/callback" component={ImplicitCallback} />
                        <Redirect to="/create-shipplan" />
                    </Switch>
                </Security>
            </div>
        </Layout>
    </BrowserRouter>
);

export default Routes;
