import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import swoosh from '../../assets/img/swooshblack.jpg';
import './Header.scss';

const Header = () => (
    <header className="app-header">
        <div className="logo-container">
            <Link to="/">
                <img src={swoosh} alt="Nike" className="logo" />
            </Link>
            <h1 className="app-name">Okuru</h1>
        </div>
        <Container>
            <Row>
                <Col xs={12}>
                    <nav className="navigation">
                        <NavLink to="/create-shipplan">Create Ship plan</NavLink>
                        <NavLink to="/view-shipplan">View Ship plan</NavLink>
                        <NavLink to="/documentation">Documentation</NavLink>
                    </nav>
                </Col>
            </Row>
        </Container>
    </header>
);

export default Header;
