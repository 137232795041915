import { connect } from 'react-redux';
import { getShipplan, toggleSidebar } from '../../actions/shipplan';
import ViewShipplanSidebar from './ViewShipplanSidebar';

const mapActionCreators = {
    toggleSidebar,
    getShipplan
};

const mapStateToProps = state => ({
    open: state.shipplan.sidebarOpen,
    fixedRegion: state.shipplan.groupBy === 'material' ? 'netherlands' : undefined
});

export default connect(mapStateToProps, mapActionCreators)(ViewShipplanSidebar);
