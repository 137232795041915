import React from 'react';
import cn from 'classnames';
import { func, string, arrayOf, oneOf, oneOfType, node, shape, bool } from 'prop-types';
import './Toggle.scss';

const Toggle = ({ onChange, active, items, disabled, orientation }) => (
    <div className={cn('Toggle', `Toggle--orientation-${orientation}`)}>
        {items.map(({ title, value, icon, activeOn = [value] }) => (
            <button
                className={cn('Toggle__button', {
                    'Toggle__button--active': activeOn.includes(active)
                })}
                type="button"
                onClick={() => {
                    if (activeOn.includes(active)) {
                        return;
                    }
                    onChange(value);
                }}
                key={value}
                disabled={disabled}
                title={title}
            >
                {icon}
            </button>
        ))}
    </div>
);

Toggle.propTypes = {
    onChange: func.isRequired,
    items: arrayOf(
        shape({
            icon: oneOfType([node, string]),
            value: string,
            title: string,
            activeOn: arrayOf(string)
        })
    ).isRequired,
    active: string,
    disabled: bool,
    orientation: oneOf(['horizontal', 'vertical'])
};

Toggle.defaultProps = {
    orientation: 'horizontal'
};

export default Toggle;
