import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import FileDrop from '../FileDrop/FileDrop';
import Loader from '../Loader/Loader';
import './UploadFile.scss';
import uploadFileService from '../../services/uploadFileService';

export default class UploadFile extends Component {
    static propTypes = {
        title: string.isRequired,
        endpoint: string.isRequired,
        onError: func,
        mimeType: string,
        onToggle: func,
        onSuccess: func,
        resetAfterUpload: bool
    };

    static defaultProps = {
        onSuccess: () => {},
        onError: () => {},
        resetAfterUpload: false
    };

    state = {
        uploading: false,
        success: undefined,
        error: undefined
    };

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    handleFileDrop = async acceptedFiles => {
        const { endpoint, onSuccess, onError, resetAfterUpload } = this.props;
        if (!acceptedFiles.length) return;
        const { name, type } = acceptedFiles[0];

        this.setState({ uploading: true });
        try {
            const getSignedUploadURL = await uploadFileService.getSignedUrl(name, type);
            if (getSignedUploadURL.data.status === 'error') {
                throw new Error(getSignedUploadURL.data.message || 'Unknown error');
            }
            await uploadFileService.uploadFileOnSignedUrl(getSignedUploadURL.data.url, acceptedFiles[0]);
            const response = await uploadFileService.post(endpoint, name);
            this.setState({
                error: undefined,
                uploading: false,
                success: response.data.message
            });
            this.timeout = setTimeout(() => {
                onSuccess();
                if (resetAfterUpload) {
                    this.setState({ success: null });
                }
            }, 3000);
        } catch (error) {
            this.setState({ error: error.message, uploading: false });
            onError(name, error);
        }
    };

    handleErrorToggle = () => {
        const { onToggle } = this.props;

        this.setState({
            uploading: false,
            error: undefined
        });

        if (onToggle) {
            onToggle();
        }
    };

    render() {
        const { title, mimeType, onToggle } = this.props;
        const { uploading, error, success } = this.state;

        if (uploading) {
            return (
                <span className="upload-file upload-file--uploading">
                    <span>
                        <Loader width={16} /> Uploading...
                    </span>
                </span>
            );
        }

        if (error) {
            return (
                <div className="upload-file upload-file--error">
                    <button type="button" className="upload-file--reupload" onClick={this.handleErrorToggle}>
                        <FontAwesomeIcon icon={faSyncAlt} />
                    </button>
                    <span>{error}</span>
                </div>
            );
        }

        if (success) {
            return <span className="upload-file upload-file--success">{success}</span>;
        }

        return (
            <FileDrop
                title={title}
                acceptedMimeTypes={mimeType}
                onDrop={this.handleFileDrop}
                multiple={false}
                onToggle={onToggle}
            />
        );
    }
}
