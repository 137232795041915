import React from 'react';
import moment from 'moment';
import { string, number, func, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import './FileInfo.scss';

const FileInfo = ({ filename, createdAt, totalQuantity, onToggle, showToggle }) => (
    <div className="FileInfo">
        {showToggle && (
            <button type="button" className="FileInfo__reupload" onClick={onToggle}>
                <FontAwesomeIcon icon={faSyncAlt} />
            </button>
        )}
        <FontAwesomeIcon icon={faTable} />
        <span className="FileInfo__name">{filename}</span>
        {createdAt && <span className="FileInfo__created">- {moment.unix(createdAt).calendar()}</span>}
        {totalQuantity && <div className="FileInfo__quantity">Total quantity: {totalQuantity.toLocaleString()}</div>}
    </div>
);

FileInfo.propTypes = {
    onToggle: func.isRequired,
    filename: string.isRequired,
    createdAt: number,
    totalQuantity: number,
    showToggle: bool
};

FileInfo.defaultProps = {
    totalQuantity: undefined,
    showToggle: false
};

export default FileInfo;
