import { connect } from 'react-redux';
import { setGroupBy, getShipplan } from '../../actions/shipplan';
import GroupBy from './GroupBy';

const mapActionCreators = {
    setGroupBy,
    getShipplan
};

const mapStateToProps = state => ({
    active: state.shipplan.groupBy
});

export default connect(mapStateToProps, mapActionCreators)(GroupBy);
