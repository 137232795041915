import React from 'react';
import { func, string } from 'prop-types';
import './FilterInput.scss';

const FilterInput = ({ onChange, value, field, ...rest }) => (
    <div className="filter-input-inner-wrapper">
        <input {...rest} onChange={onChange} value={value} placeholder={`filter by ${field.split('_').join(' ')}`} />
        <hr aria-hidden="true" />
    </div>
);

FilterInput.propTypes = {
    onChange: func,
    value: string,
    field: string
};

FilterInput.defaultProps = {};

export default FilterInput;
