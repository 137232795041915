import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faGlobeEurope, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { groupBy } from '../GroupBy/GroupBy';
import OverrideMaterialContainer from '../Override/OverrideMaterialContainer';
import OverrideMaterialStoreContainer from '../Override/OverrideMaterialStoreContainer';
import UnitCellContainer from '../UnitCell/UnitCellContainer';
import UnitCell from '../UnitCell/UnitCell';

const materialColumn = {
    Header: 'Material',
    accessor: 'Material',
    width: 120,
    sortable: false,
    Cell: ({ value }) => (
        <span>
            <Link to={`/view-shipplan/${value}`}>{value}</Link>
        </span>
    )
};

const productDescriptionColumn = {
    Header: 'Description',
    accessor: 'ProductDescription',
    width: 300,
    sortable: false,
    Cell: ({ value }) => <span>{value}</span>
};

const retailPriceColumn = region => ({
    Header: (
        <span>
            {region === 'local' ? <FontAwesomeIcon icon={faMapMarkedAlt} /> : <FontAwesomeIcon icon={faGlobeEurope} />}{' '}
            Retail
        </span>
    ),
    accessor: 'retailPrice',
    width: 80,
    sortable: false,
    Cell: ({ value }) => <UnitCell value={value} unitType="price" priceModel="retail" priceRegion={region} />
});
const wholesalePriceColumn = region => ({
    Header: (
        <span title="Wholesale">
            {region === 'local' ? <FontAwesomeIcon icon={faMapMarkedAlt} /> : <FontAwesomeIcon icon={faGlobeEurope} />} WHSL
        </span>
    ),
    accessor: 'wholesalePrice',
    width: 80,
    sortable: false,
    Cell: ({ value }) => <UnitCell value={value} unitType="price" priceModel="wholesale" priceRegion={region} />
});

const partnerColumn = {
    Header: 'PartnerName',
    accessor: 'PartnerName',
    sortable: false,
    width: 120
};

const categoryColumn = {
    Header: 'Category',
    accessor: 'Category',
    sortable: false,
    width: 200
};

const divisionColumn = {
    Header: 'Division',
    accessor: 'Division',
    sortable: false,
    width: 100
};

const storeNumberColumn = {
    Header: 'Store',
    accessor: 'StoreNumber',
    sortable: false,
    width: 60
};

const storeNameColumn = {
    Header: 'Store name',
    accessor: 'StoreName',
    sortable: false,
    width: 240
};
const aaReplenColumn = {
    Header: 'AA / R',
    getHeaderProps: () => ({ title: 'AA / Replenishment' }),
    accessor: 'AAReplen',
    sortable: false,
    width: 60,
    className: 'rt-td--narrow'
};
const carryOverColumn = {
    Header: 'CO',
    getHeaderProps: () => ({ title: 'Carry over' }),
    accessor: 'CarryOverQuantity',
    width: 60,
    className: 'rt-td--narrow rt-td--center',
    Cell: ({ value }) => <span>{value !== null ? <FontAwesomeIcon icon={faCheck} title="Carry over" /> : null}</span>
};

const renderFlowColumn = ({
    flowNumber,
    columnWidth = 60,
    produced = false,
    materialOverride = null,
    materialStoreOverride = null,
    noHeader = false
} = {}) => {
    const accessor = flowNumber !== undefined ? `flow${flowNumber}` : 'total';
    const title = flowNumber !== undefined ? `Flow ${flowNumber}` : 'Full season';
    const key = flowNumber !== undefined ? `f${flowNumber}` : 'total';
    const columns = [
        {
            Header: 'Buyplan',
            accessor: `${accessor}.Buy`,
            id: `${key}Buy`,
            width: columnWidth,
            className: 'rt-td--first',
            headerClassName: 'rt-th--flow rt-th--flow--first',
            Cell: ({ value, original }) => <UnitCellContainer value={value} country={original.Country} />
        }
    ];

    if (produced) {
        columns.push({
            Header: 'Orderbook',
            accessor: `${accessor}.Produced`,
            id: `${key}Produced`,
            width: columnWidth,
            headerClassName: 'rt-th--flow',
            Cell: ({ value, original }) => <UnitCellContainer value={value} country={original.Country} />
        });
    }

    // TODO: Very bad to rely on materialOverride being not null instead of specifying it in the call
    if (flowNumber && materialOverride !== null) {
        columns.push({
            Header: 'Proposed',
            accessor: `${accessor}.Override`,
            id: `${key}Override`,
            sortable: false,
            width: columnWidth,
            className: materialOverride ? 'rt-td--no-padding rt-td--overflow-visible' : '',
            headerClassName: 'rt-th--flow',
            Cell: cellProps => {
                const { original } = cellProps;
                const flow = original[accessor];
                if (materialOverride && original.AAReplen !== 'AA') {
                    return (
                        <OverrideMaterialContainer
                            material={original.Material}
                            hasOverride={flow.HasOverride}
                            coverage={flow.Coverage}
                            flowNumber={flowNumber}
                            maximum={flow.Maximum}
                            softMin={flow.SoftMin}
                            disabled={original.disabled}
                            decisionStatement={original.decisionStatement}
                        />
                    );
                }
                return (
                    <span className="rt-td--no-override">
                        <UnitCellContainer value={flow.Coverage} country={original.Country} />
                    </span>
                );
            }
        });
    }

    // TODO: Very bad to rely on materialOverride being not null instead of specifying it in the call
    if (flowNumber && materialStoreOverride !== null) {
        columns.push({
            Header: '(loadIn / replen) Proposed',
            accessor: `${accessor}.Override`,
            id: `${key}Override`,
            sortable: false,
            width: columnWidth * 2,
            className: materialStoreOverride ? 'rt-td--no-padding rt-td--overflow-visible' : '',
            headerClassName: 'rt-th--flow',
            Cell: cellProps => {
                const { original } = cellProps;
                const flow = original[accessor];
                if (materialStoreOverride) {
                    return (
                        <OverrideMaterialStoreContainer
                            material={original.Material}
                            storeNumber={original.StoreNumber}
                            hasOverride={flow.HasOverride}
                            loadIn={flow.LoadIn}
                            replen={flow.Replen}
                            flowNumber={flowNumber}
                            maximum={flow.Maximum}
                            disabled={original.disabled}
                        />
                    );
                }
                return (
                    <div className="Override Override--materialstore">
                        <UnitCellContainer value={flow.LoadIn} country={original.Country} />
                        <UnitCellContainer value={flow.Replen} country={original.Country} />
                    </div>
                );
            }
        });
    }

    columns.push({
        Header: 'Total',
        accessor: `${accessor}.Coverage`,
        id: `${key}Coverage`,
        width: columnWidth,
        headerClassName: 'rt-th--flow',
        Cell: ({ value, original }) => <UnitCellContainer value={value} country={original.Country} />
    });

    columns.push({
        Header: 'Variance',
        accessor: `${accessor}.Variance`,
        id: `${key}Variance`,
        width: columnWidth,
        headerClassName: 'rt-th--flow',
        className: flowNumber === undefined ? 'rt-td--last' : '',
        Cell: ({ value, original }) => <UnitCellContainer value={value} country={original.Country} />
    });

    return {
        Header: noHeader ? null : title,
        headerClassName: `rt-th--flow rt-th--flow--first rt-th--flow-${key}`,
        columns
    };
};

export const getTotalColumnsWidth = headers =>
    headers.reduce((total, header) => header.columns.reduce((subTotal, column) => subTotal + column.width, total), 0);

export const renderFlowColumns = settings => [
    renderFlowColumn({ flowNumber: 1, ...settings }),
    renderFlowColumn({ flowNumber: 2, ...settings }),
    renderFlowColumn({ flowNumber: 3, ...settings }),
    renderFlowColumn(settings)
];

export default ({ group, header = null, override = null, unitType = 'quantity' }) => {
    const extraColumnWidth = unitType === 'price' ? 50 : 0;
    switch (group) {
        case groupBy.PARTNER: {
            return [partnerColumn, ...renderFlowColumns({ columnWidth: 80 + extraColumnWidth })];
        }
        case groupBy.CATEGORY: {
            return [categoryColumn, ...renderFlowColumns({ columnWidth: 80 + extraColumnWidth })];
        }
        case groupBy.DIVISION: {
            return [divisionColumn, ...renderFlowColumns({ columnWidth: 90 + extraColumnWidth })];
        }
        case groupBy.STORE: {
            return [
                partnerColumn,
                storeNumberColumn,
                storeNameColumn,
                ...renderFlowColumns({ columnWidth: 70 + extraColumnWidth })
            ];
        }
        case groupBy.MATERIAL: {
            return [
                {
                    Header: header,
                    headerClassName: 'rt-th--custom-header',
                    columns: [
                        materialColumn,
                        productDescriptionColumn,
                        retailPriceColumn('netherlands'),
                        wholesalePriceColumn('netherlands'),
                        aaReplenColumn
                    ]
                },
                ...renderFlowColumns({
                    produced: true,
                    materialOverride: override && unitType === 'quantity',
                    columnWidth: 80 + extraColumnWidth
                })
            ];
        }
        case groupBy.MATERIAL_STORE: {
            return [
                partnerColumn,
                storeNumberColumn,
                storeNameColumn,
                retailPriceColumn('local'),
                wholesalePriceColumn('local'),
                carryOverColumn,
                ...renderFlowColumns({
                    materialStoreOverride: override && unitType === 'quantity',
                    columnWidth: 80 + extraColumnWidth,
                    noHeader: true
                })
            ];
        }
        case groupBy.SEASON:
        default: {
            return [...renderFlowColumns({ columnWidth: 100 + extraColumnWidth })];
        }
    }
};
