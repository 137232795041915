import React from 'react';
import { func, string, node, bool, object } from 'prop-types';
import ReactModal from 'react-modal';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import Button from '../Button/Button';
import './Modal.scss';

ReactModal.defaultStyles.overlay.zIndex = 9;

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const defaults = {
    okButtonText: 'OK',
    cancelButtonText: 'CANCEL'
};

const mergeStyles = (style = {}) => {
    let outerStyle = style;
    if (style && !style.content) {
        outerStyle = { ...style, content: {} };
    }
    const { content, ...restStyles } = modalStyles;
    const { content: outerContent, ...outerRestStyles } = outerStyle;

    return {
        content: { ...content, ...outerContent },
        ...restStyles,
        ...outerRestStyles
    };
};

const Modal = ({
    closeAction,
    okAction,
    okButtonText,
    okButtonType,
    cancelAction,
    cancelButtonText,
    title,
    subtitle,
    text,
    children,
    className,
    innerComponentClassName,
    isOpen,
    autoCloseAfterAction,
    style
}) => (
    <ReactModal className={className} isOpen={isOpen} style={mergeStyles(style)} onRequestClose={closeAction}>
        <div className={cn(innerComponentClassName)}>
            <div className="modal-header">
                <h1 className="modal-title">{title}</h1>
                {subtitle && <span className="modal-subtitle">{subtitle}</span>}
                <span className="modal-close" role="button" tabIndex={0} title="close" onClick={closeAction}>
                    <FontAwesomeIcon icon={faWindowClose} className="close-icon" size="lg" />
                </span>
            </div>
            <div className="modal-body">
                <span className="modal-text">{text}</span>
                {children && <div className="modal-children">{children}</div>}
                {(okAction || cancelAction) && (
                    <div className="modal-button-container">
                        {okAction && (
                            <Button
                                className={cn('Button-fullWidth okButton', { danger: okButtonType === 'danger' })}
                                onClick={() => {
                                    okAction();
                                    if (autoCloseAfterAction && closeAction) {
                                        closeAction();
                                    }
                                }}
                            >
                                {okButtonText || defaults.okButtonText}
                            </Button>
                        )}

                        {cancelAction && (
                            <Button
                                className="Button-fullWidth cancelButton Button__inverted"
                                onClick={() => {
                                    cancelAction();
                                    if (autoCloseAfterAction && closeAction) {
                                        closeAction();
                                    }
                                }}
                            >
                                {cancelButtonText || defaults.cancelButtonText}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    </ReactModal>
);

Modal.propTypes = {
    children: node,
    closeAction: func.isRequired,
    okAction: func,
    cancelAction: func,
    className: string,
    innerComponentClassName: string,
    title: string,
    subtitle: string,
    text: string,
    okButtonText: string,
    okButtonType: string,
    cancelButtonText: string,
    isOpen: bool,
    autoCloseAfterAction: bool,
    style: object
};

Modal.defaultProps = {
    subtitle: ''
};

export default Modal;
