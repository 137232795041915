import React from 'react';
import { string, func } from 'prop-types';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import './InvalidPartnerProposal.scss';

const InvalidPartnerProposalModal = ({ uploadErrorMessage, onClose }) => {
    const modalIsOpen = Boolean(uploadErrorMessage);

    return (
        <Modal
            isOpen={modalIsOpen}
            closeAction={onClose}
            title="Something went wrong"
            subtitle="while uploading the partner proposal"
        >
            <div className="InvalidPartnerProposal">
                <p className="InvalidPartnerProposal__error">{uploadErrorMessage}</p>
                <Button className="Button__inverted" onClick={onClose}>
                    Close
                </Button>
            </div>
        </Modal>
    );
};

InvalidPartnerProposalModal.propTypes = {
    uploadErrorMessage: string,
    onClose: func.isRequired
};

export default InvalidPartnerProposalModal;
