import React, { Fragment } from 'react';
import { func, string, arrayOf, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { getToken } from '../../api/api';
import { Step } from '../Stepper/Stepper';
import StepIndicator, {
    INDICATOR_IDLE,
    INDICATOR_COMPLETED,
    INDICATOR_WAITING_ON_USER
} from '../StepIndicator/StepIndicator';
import ConfirmButton from '../ConfirmButton/ConfirmButton';

const CreatePartnerProposalsStep = ({ createPartnerProposals, error, loading, actions, seasonStatus }) => {
    let status = INDICATOR_IDLE;
    if (actions.includes('createPartnerProposals') || actions.includes('managePartnerProposals')) {
        status = INDICATOR_WAITING_ON_USER;
    } else if (seasonStatus === 'proposalsApplied') {
        status = INDICATOR_COMPLETED;
    }
    return (
        <Step title="Partner proposals" indicator={<StepIndicator state={status} />} last>
            {actions.includes('createPartnerProposals') && (
                <Fragment>
                    <ConfirmButton
                        onClick={createPartnerProposals}
                        label="Create partner proposals"
                        confirmLabel="Really create partner proposals?"
                        loading={loading}
                    />
                    <p>
                        This will temporary prevent manual changes being made to the shipplan until all partner proposals are
                        uploaded and applied. See the <Link to="/documentation/partner-proposals">documentation</Link> for
                        more information.
                    </p>
                </Fragment>
            )}
            {actions.includes('managePartnerProposals') && (
                <Fragment>
                    <Link to="/partner-proposals" className="Button">
                        Manage partner proposals
                    </Link>
                    <p>
                        Here you can download and upload the proposal files for the partners. See the{' '}
                        <Link to="/documentation/partner-proposals">documentation</Link> for more information.
                    </p>
                </Fragment>
            )}
            {actions.includes('exportPartnerConfirmations') && (
                <a
                    href={`${
                        process.env.REACT_APP_API_ENDPOINT
                    }/seasons/current/partner-proposals/confirmations?${qs.stringify({
                        Authorization: `Bearer ${getToken()}`
                    })}`}
                    download
                    className="Button"
                >
                    Download partner confirmation files (.zip)
                </a>
            )}
            {error && (
                <p className="error">
                    <FontAwesomeIcon icon={faExclamationTriangle} /> {error}
                </p>
            )}
        </Step>
    );
};

CreatePartnerProposalsStep.propTypes = {
    createPartnerProposals: func.isRequired,
    error: string,
    actions: arrayOf(string),
    seasonStatus: string,
    loading: bool
};

export default CreatePartnerProposalsStep;
