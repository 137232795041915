import { connect } from 'react-redux';
import ExportShipplanViewButton from './ExportShipplanViewButton';

const mapDispatchToProps = {};

const mapStateToProps = ({ shipplan }) => ({
    activeFilters: shipplan.activeFilters,
    sorting: shipplan.sorting,
    groupBy: shipplan.groupBy,
    unitType: shipplan.unitType,
    priceRegion: shipplan.priceRegion,
    priceModel: shipplan.priceModel,
    rows: shipplan.rows
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportShipplanViewButton);
