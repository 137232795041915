import { connect } from 'react-redux';
import { getTotalLenghtOfAllArraysWithinObject } from '../../helpers/utils';
import { getShipplanMetaData, setFilter, clearFilter, getShipplan } from '../../actions/shipplan';
import FilterGroup from './FilterGroup';

const countActiveFilterAmount = activeFilters => getTotalLenghtOfAllArraysWithinObject(activeFilters);

const mapDispatchToProps = {
    getShipplan,
    getShipplanMetaData,
    setFilter,
    clearFilter
};

const mapStateToProps = state => ({
    activeFilterAmount: countActiveFilterAmount(state.shipplan.activeFilters),
    metaDataPending: state.shipplan.getShipplanMetaDataPending,
    activeFilters: state.shipplan.activeFilters,
    filters: state.shipplan.metaData,
    rows: state.shipplan.rows
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterGroup);
