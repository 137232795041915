import { combineReducers } from 'redux';
import season from '../reducers/season';
import shipplan from '../reducers/shipplan';
import override from '../reducers/override';
import material from '../reducers/material';
import partnerProposals from '../reducers/partnerProposals';
import globalError from '../reducers/globalError';
import unitToggle from '../reducers/unitToggle';

const makeRootReducer = () =>
    combineReducers({
        season,
        shipplan,
        override,
        material,
        partnerProposals,
        globalError,
        unitToggle
    });

export default makeRootReducer;
